export const createSchoolStudent = /* GraphQL */ `
  mutation CreateSchoolStudent(
    $input: CreateSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    createSchoolStudent(input: $input, condition: $condition) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      createdAt
      updatedAt
    }
  }
`;

export const updateSchoolStudentNames = /* GraphQL */ `
  mutation UpdateSchoolStudent(
    $input: UpdateSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    updateSchoolStudent(input: $input, condition: $condition) {
      id
      firstName
      lastName
    }
  }
`;

export const updateSchoolStudent = /* GraphQL */ `
  mutation UpdateSchoolStudent(
    $input: UpdateSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    updateSchoolStudent(input: $input, condition: $condition) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      firstName
      lastName
      userId
    }
  }
`;

export const deleteClassroomStudent = /* GraphQL */ `
  mutation DeleteClassroomStudent(
    $input: DeleteClassroomStudentInput!
    $condition: ModelClassroomStudentConditionInput
  ) {
    deleteClassroomStudent(input: $input, condition: $condition) {
      id
      classroomID
      studentID
    }
  }
`;

export const updateSchoolNetworkId = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      schoolName
      networkID
    }
  }
`;

export const createSchoolAttributeCategory = /* GraphQL */ `
  mutation CreateSchoolAttributeCategory(
    $input: CreateSchoolAttributeCategoryInput!
    $condition: ModelSchoolAttributeCategoryConditionInput
  ) {
    createSchoolAttributeCategory(input: $input, condition: $condition) {
      id
      schoolID
      categoryName
    }
  }
`;

export const updateSchoolAttributeCategory = /* GraphQL */ `
  mutation UpdateSchoolAttributeCategory(
    $input: UpdateSchoolAttributeCategoryInput!
    $condition: ModelSchoolAttributeCategoryConditionInput
  ) {
    updateSchoolAttributeCategory(input: $input, condition: $condition) {
      id
      schoolID
      categoryName
    }
  }
`;

export const createStudentData = /* GraphQL */ `
  mutation CreateStudentData(
    $input: CreateStudentDataInput!
    $condition: ModelStudentDataConditionInput
  ) {
    createStudentData(input: $input, condition: $condition) {
      id
      studentID
      schoolYear
      attributeID
      value
    }
  }
`;

export const updateStudentData = /* GraphQL */ `
  mutation UpdateStudentData(
    $input: UpdateStudentDataInput!
    $condition: ModelStudentDataConditionInput
  ) {
    updateStudentData(input: $input, condition: $condition) {
      id
      studentID
      schoolYear
      attributeID
      value
    }
  }
`;

export const deleteStudentHandwritingLog = /* GraphQL */ `
  mutation DeleteStudentHandwritingLog(
    $input: DeleteStudentHandwritingLogInput!
    $condition: ModelStudentHandwritingLogConditionInput
  ) {
    deleteStudentHandwritingLog(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteHandwritingLog = /* GraphQL */ `
  mutation DeleteHandwritingLog(
    $input: DeleteHandwritingLogInput!
    $condition: ModelHandwritingLogConditionInput
  ) {
    deleteHandwritingLog(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteStudentData = /* GraphQL */ `
  mutation DeleteStudentData(
    $input: DeleteStudentDataInput!
    $condition: ModelStudentDataConditionInput
  ) {
    deleteStudentData(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      wondeID
      MISID
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      yearLevelID
      createdAt
      updatedAt
      currentYear {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      classrooms {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolYears {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
      studentData {
        items {
          id
          studentID
          schoolYear
          attributeID
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      firstName
      lastName
      photo {
        bucket
        region
        key
      }
      classrooms {
        items {
          id
        }
      }
      schoolYears {
        items {
          id
        }
      }
      studentData {
        items {
          id
        }
      }
    }
  }
`;
export const deleteSchoolStudent = /* GraphQL */ `
  mutation DeleteSchoolStudent(
    $input: DeleteSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    deleteSchoolStudent(input: $input, condition: $condition) {
      id
      schoolID
      studentID
      schoolYear
      userId
    }
  }
`;

export const createAlternativeStudentName = /* GraphQL */ `
  mutation CreateAlternativeStudentName(
    $input: CreateAlternativeStudentNameInput!
    $condition: ModelAlternativeStudentNameConditionInput
  ) {
    createAlternativeStudentName(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      fullName
      source
      schoolID
      studentID
    }
  }
`;

export const updateAlternativeStudentName = /* GraphQL */ `
  mutation UpdateAlternativeStudentName(
    $input: UpdateAlternativeStudentNameInput!
    $condition: ModelAlternativeStudentNameConditionInput
  ) {
    updateAlternativeStudentName(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      fullName
      source
      schoolID
      studentID
    }
  }
`;

export const deleteAlternativeStudentName = /* GraphQL */ `
  mutation DeleteAlternativeStudentName(
    $input: DeleteAlternativeStudentNameInput!
    $condition: ModelAlternativeStudentNameConditionInput
  ) {
    deleteAlternativeStudentName(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      fullName
      source
      schoolID
      studentID
    }
  }
`;

export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      email
      userGroup
      userType
      enabled
      avatar {
        bucket
        region
        key
      }
      userSchoolID
      lastSignIn
      dbType
      createdAt
      updatedAt
    }
  }
`;

export const deleteClassroomTeacher = /* GraphQL */ `
  mutation DeleteClassroomTeacher(
    $input: DeleteClassroomTeacherInput!
    $condition: ModelClassroomTeacherConditionInput
  ) {
    deleteClassroomTeacher(input: $input, condition: $condition) {
      id
      classroomID
      email
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      email
      userGroup
      userType
      enabled
      userSchoolID
      lastSignIn
      dbType
      isArchived
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      email
      userGroup
      userType
      enabled
      userSchoolID
      lastSignIn
      dbType
    }
  }
`;

export const updatePrompt = /* GraphQL */ `
  mutation UpdatePrompt(
    $input: UpdatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    updatePrompt(input: $input, condition: $condition) {
      id
      taskType
      promptName
      text
      stimulus
      sharedByUserEmail
      sharedWithUserEmails
      isArchived
      createdAt
      updatedAt
    }
  }
`;

export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      dataType
      typeID
      testName
      shortName
      testYearLevelId
      year
      nationalMean
      testUnitID
      yearLevel {
        id
        description
      }
      testType {
        id
        typeName
      }
      testUnit {
        id
        unitName
      }
    }
  }
`;

export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      typeID
      shortName
      testName
      testYearLevelId
      year
      nationalMean
      testUnitID
      sharedWithUserIDs
      sharedByUserID
      yearLevel {
        id
        description
      }
      testType {
        id
        typeName
      }
      testUnit {
        id
        unitName
      }
      isArchived
    }
  }
`;

export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      testName
      questions {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

export const createTestQuestion = /* GraphQL */ `
  mutation CreateTestQuestion(
    $input: CreateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    createTestQuestion(input: $input, condition: $condition) {
      id
      testID
      questionNo
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      acCode {
        id
        acCode
        skill
        learningArea {
          id
          areaName
        }
        strand {
          id
          strandName
        }
        substrand {
          id
          substrandName
        }
        yearLevel {
          id
          description
        }
      }
    }
  }
`;
export const updateTestQuestion = /* GraphQL */ `
  mutation UpdateTestQuestion(
    $input: UpdateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    updateTestQuestion(input: $input, condition: $condition) {
      id
      testID
      questionNo
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      acCode {
        id
        acCode
        skill
        learningArea {
          id
          areaName
        }
        strand {
          id
          strandName
        }
        substrand {
          id
          substrandName
        }
        yearLevel {
          id
          description
        }
      }
    }
  }
`;

export const deleteTestQuestion = /* GraphQL */ `
  mutation DeleteTestQuestion(
    $input: DeleteTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    deleteTestQuestion(input: $input, condition: $condition) {
      id
      testID
    }
  }
`;

export const createTestUpload = /* GraphQL */ `
  mutation CreateTestUpload(
    $input: CreateTestUploadInput!
    $condition: ModelTestUploadConditionInput
  ) {
    createTestUpload(input: $input, condition: $condition) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      resultFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateTestUpload = /* GraphQL */ `
  mutation UpdateTestUpload(
    $input: UpdateTestUploadInput!
    $condition: ModelTestUploadConditionInput
  ) {
    updateTestUpload(input: $input, condition: $condition) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      isHideScoresFromStudents
      resultFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteTestUpload = /* GraphQL */ `
  mutation DeleteTestUpload(
    $input: DeleteTestUploadInput!
    $condition: ModelTestUploadConditionInput
  ) {
    deleteTestUpload(input: $input, condition: $condition) {
      id
      testDate
      resultFile {
        bucket
        region
        key
      }
      testResults {
        items {
          id
          resultAnswers {
            items {
              id
            }
          }
          learningAreas {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const deleteTestResult = /* GraphQL */ `
  mutation DeleteTestResult(
    $input: DeleteTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    deleteTestResult(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteTestResultAnswers = /* GraphQL */ `
  mutation DeleteTestResultAnswers(
    $input: DeleteTestResultAnswersInput!
    $condition: ModelTestResultAnswersConditionInput
  ) {
    deleteTestResultAnswers(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteTestResultLearningArea = /* GraphQL */ `
  mutation DeleteTestResultLearningArea(
    $input: DeleteTestResultLearningAreaInput!
    $condition: ModelTestResultLearningAreaConditionInput
  ) {
    deleteTestResultLearningArea(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteClassroomYearLevel = /* GraphQL */ `
  mutation DeleteClassroomYearLevel(
    $input: DeleteClassroomYearLevelInput!
    $condition: ModelClassroomYearLevelConditionInput
  ) {
    deleteClassroomYearLevel(input: $input, condition: $condition) {
      id
      classroomID
      schoolID
      yearLevelID
    }
  }
`;

export const createClassroomYearLevel = /* GraphQL */ `
  mutation CreateClassroomYearLevel(
    $input: CreateClassroomYearLevelInput!
    $condition: ModelClassroomYearLevelConditionInput
  ) {
    createClassroomYearLevel(input: $input, condition: $condition) {
      id
      classroomID
      schoolID
      yearLevelID
    }
  }
`;

export const updateClassroomYearLevel = /* GraphQL */ `
  mutation UpdateClassroomYearLevel(
    $input: UpdateClassroomYearLevelInput!
    $condition: ModelClassroomYearLevelConditionInput
  ) {
    updateClassroomYearLevel(input: $input, condition: $condition) {
      id
      classroomID
      schoolID
      yearLevelID
      yearLevel {
        id
        yearCode
        description
      }
    }
  }
`;

export const updateClassroom = /* GraphQL */ `
  mutation UpdateClassroom(
    $input: UpdateClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    updateClassroom(input: $input, condition: $condition) {
      id
      className
      schoolID
      isArchived
    }
  }
`;

export const createClassroomStudent = /* GraphQL */ `
  mutation CreateClassroomStudent(
    $input: CreateClassroomStudentInput!
    $condition: ModelClassroomStudentConditionInput
  ) {
    createClassroomStudent(input: $input, condition: $condition) {
      id
      classroomID
      studentID
      createdAt
      updatedAt
    }
  }
`;

export const updateClassroomTeacher = /* GraphQL */ `
  mutation UpdateClassroomTeacher(
    $input: UpdateClassroomTeacherInput!
    $condition: ModelClassroomTeacherConditionInput
  ) {
    updateClassroomTeacher(input: $input, condition: $condition) {
      id
      classroomID
      email
      createdAt
      updatedAt
    }
  }
`;

export const createTestResult = /* GraphQL */ `
  mutation CreateTestResult(
    $input: CreateTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    createTestResult(input: $input, condition: $condition) {
      id
      testID
      testDate
      completedDate
      studentID
      typeID
      schoolYear
      yearLevelID
      schoolID
      score
      scale
      stanine
      percentile
      testUploadID
      createdAt
      updatedAt
    }
  }
`;

export const createTestResultAnswers = /* GraphQL */ `
  mutation CreateTestResultAnswers(
    $input: CreateTestResultAnswersInput!
    $condition: ModelTestResultAnswersConditionInput
  ) {
    createTestResultAnswers(input: $input, condition: $condition) {
      id
      testResultID
      testQuestionID
      studentAnswer
      proficiency
      createdAt
      updatedAt
    }
  }
`;

export const createTestLearningArea = /* GraphQL */ `
  mutation CreateTestLearningArea(
    $input: CreateTestLearningAreaInput!
    $condition: ModelTestLearningAreaConditionInput
  ) {
    createTestLearningArea(input: $input, condition: $condition) {
      id
      testID
      learningAreaID
      createdAt
      updatedAt
    }
  }
`;

export const updateTestLearningArea = /* GraphQL */ `
  mutation UpdateTestLearningArea(
    $input: UpdateTestLearningAreaInput!
    $condition: ModelTestLearningAreaConditionInput
  ) {
    updateTestLearningArea(input: $input, condition: $condition) {
      id
      testID
      learningAreaID
      createdAt
      updatedAt
    }
  }
`;

export const deleteTestLearningArea = /* GraphQL */ `
  mutation DeleteTestLearningArea(
    $input: DeleteTestLearningAreaInput!
    $condition: ModelTestLearningAreaConditionInput
  ) {
    deleteTestLearningArea(input: $input, condition: $condition) {
      id
      testID
      learningAreaID
    }
  }
`;

export const createTestResultLearningArea = /* GraphQL */ `
  mutation CreateTestResultLearningArea(
    $input: CreateTestResultLearningAreaInput!
    $condition: ModelTestResultLearningAreaConditionInput
  ) {
    createTestResultLearningArea(input: $input, condition: $condition) {
      id
      testResultID
      studentID
      learningAreaID
      typeID
      schoolYear
      yearLevelID
      schoolID
      testDate
      createdAt
      updatedAt
    }
  }
`;

export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      schoolName
      motto
      studentLoginEnabled
      networkID
      countryID
      stateID
      logo {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      country {
        id
        name
        countryCode
      }
      state {
        id
        name
        stateCode
      }
    }
  }
`;

export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      schoolName
      motto
      studentLoginEnabled
      networkID
      countryID
      stateID
      logo {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      country {
        id
        name
        countryCode
      }
      state {
        id
        name
        stateCode
      }
    }
  }
`;

export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      schoolName
      logo {
        bucket
        region
        key
      }
      attributeCategories {
        items {
          id
        }
      }
      schoolIcseas {
        items {
          id
        }
      }
      students {
        items {
          id
          schoolID
          studentID
          schoolYear
        }
        nextToken
      }
    }
  }
`;

export const createTestCategory = /* GraphQL */ `
  mutation CreateTestCategory(
    $input: CreateTestCategoryInput!
    $condition: ModelTestCategoryConditionInput
  ) {
    createTestCategory(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const updateTestCategory = /* GraphQL */ `
  mutation UpdateTestCategory(
    $input: UpdateTestCategoryInput!
    $condition: ModelTestCategoryConditionInput
  ) {
    updateTestCategory(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const createTestAssignment = /* GraphQL */ `
  mutation CreateTestAssignment(
    $input: CreateTestAssignmentInput!
    $condition: ModelTestAssignmentConditionInput
  ) {
    createTestAssignment(input: $input, condition: $condition) {
      id
      testDate
      schoolYear
      status
      userId
      testID
      testUploadID
      schoolID
      yearLevelID
      classroomID
      typeID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      testType {
        id
        typeName
        description
      }
    }
  }
`;
export const createApplicationFeature = /* GraphQL */ `
  mutation CreateApplicationFeature(
    $input: CreateApplicationFeatureInput!
    $condition: ModelApplicationFeatureConditionInput
  ) {
    createApplicationFeature(input: $input, condition: $condition) {
      id
      featureKey
      featureValueType
      featureDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplicationFeature = /* GraphQL */ `
  mutation UpdateApplicationFeature(
    $input: UpdateApplicationFeatureInput!
    $condition: ModelApplicationFeatureConditionInput
  ) {
    updateApplicationFeature(input: $input, condition: $condition) {
      id
      featureKey
      featureValueType
      featureDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplicationFeature = /* GraphQL */ `
  mutation DeleteApplicationFeature(
    $input: DeleteApplicationFeatureInput!
    $condition: ModelApplicationFeatureConditionInput
  ) {
    deleteApplicationFeature(input: $input, condition: $condition) {
      id
      featureKey
      featureValueType
      featureDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplicationFeatureFlagGlobal = /* GraphQL */ `
  mutation CreateApplicationFeatureFlagGlobal(
    $input: CreateApplicationFeatureFlagGlobalInput!
    $condition: ModelApplicationFeatureFlagGlobalConditionInput
  ) {
    createApplicationFeatureFlagGlobal(input: $input, condition: $condition) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplicationFeatureFlagGlobal = /* GraphQL */ `
  mutation UpdateApplicationFeatureFlagGlobal(
    $input: UpdateApplicationFeatureFlagGlobalInput!
    $condition: ModelApplicationFeatureFlagGlobalConditionInput
  ) {
    updateApplicationFeatureFlagGlobal(input: $input, condition: $condition) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplicationFeatureFlagGlobal = /* GraphQL */ `
  mutation DeleteApplicationFeatureFlagGlobal(
    $input: DeleteApplicationFeatureFlagGlobalInput!
    $condition: ModelApplicationFeatureFlagGlobalConditionInput
  ) {
    deleteApplicationFeatureFlagGlobal(input: $input, condition: $condition) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplicationFeatureFlagSchool = /* GraphQL */ `
  mutation CreateApplicationFeatureFlagSchool(
    $input: CreateApplicationFeatureFlagSchoolInput!
    $condition: ModelApplicationFeatureFlagSchoolConditionInput
  ) {
    createApplicationFeatureFlagSchool(input: $input, condition: $condition) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      schoolID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
          __typename
        }
        overrideWAMAccess
        overridePAMAccess
        lastWondeUpdateDate
        salesForceAccountID
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
          __typename
        }
        attributeCategories {
          nextToken
          __typename
        }
        schoolIcseas {
          nextToken
          __typename
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
          __typename
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateApplicationFeatureFlagSchool = /* GraphQL */ `
  mutation UpdateApplicationFeatureFlagSchool(
    $input: UpdateApplicationFeatureFlagSchoolInput!
    $condition: ModelApplicationFeatureFlagSchoolConditionInput
  ) {
    updateApplicationFeatureFlagSchool(input: $input, condition: $condition) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      schoolID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
          __typename
        }
        overrideWAMAccess
        overridePAMAccess
        lastWondeUpdateDate
        salesForceAccountID
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
          __typename
        }
        attributeCategories {
          nextToken
          __typename
        }
        schoolIcseas {
          nextToken
          __typename
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
          __typename
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteApplicationFeatureFlagSchool = /* GraphQL */ `
  mutation DeleteApplicationFeatureFlagSchool(
    $input: DeleteApplicationFeatureFlagSchoolInput!
    $condition: ModelApplicationFeatureFlagSchoolConditionInput
  ) {
    deleteApplicationFeatureFlagSchool(input: $input, condition: $condition) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      schoolID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
          __typename
        }
        overrideWAMAccess
        overridePAMAccess
        lastWondeUpdateDate
        salesForceAccountID
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
          __typename
        }
        attributeCategories {
          nextToken
          __typename
        }
        schoolIcseas {
          nextToken
          __typename
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
          __typename
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const createHelpGuide = /* GraphQL */ `
  mutation CreateHelpGuide(
    $input: CreateHelpGuideInput!
    $condition: ModelHelpGuideConditionInput
  ) {
    createHelpGuide(input: $input, condition: $condition) {
      id
      title
      description
      category
      link
      resourceLink
    }
  }
`;

export const updateHelpGuide = /* GraphQL */ `
  mutation UpdateHelpGuide(
    $input: UpdateHelpGuideInput!
    $condition: ModelHelpGuideConditionInput
  ) {
    updateHelpGuide(input: $input, condition: $condition) {
      id
      title
      description
      category
      link
      resourceLink
    }
  }
`;

export const deleteHelpGuide = /* GraphQL */ `
  mutation DeleteHelpGuide(
    $input: DeleteHelpGuideInput!
    $condition: ModelHelpGuideConditionInput
  ) {
    deleteHelpGuide(input: $input, condition: $condition) {
      id
      title
      description
      category
      link
      resourceLink
    }
  }
`;
