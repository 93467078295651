import React, { useEffect, useState } from "react";

import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CHeader,
  CRow,
  CTextarea,
} from "@coreui/react";
import Link from "../../../../../components/common/Link";
import ActionButton from "../../ActionButton";
import SelectBox from "devextreme-react/select-box";

/**
 *
 * @param {{ show: boolean, onClose: () => void, rubricCategory: String, comment: string, score: number, maxScore: number  }} props
 * @returns
 */
export const EditGrade = ({
  show,
  onClose,
  submitEdit,
  rubricCategory,
  score: initialScore,
  comment: initialComment,
  rubrics,
  prompt,
}) => {
  const [score, setScore] = useState(initialScore);
  const [comment, setComment] = useState("");

  useEffect(() => {
    setScore(initialScore);
  }, [initialScore]);

  const maxScore = rubrics?.find(
    (i) => i.rubricName.toLowerCase() === rubricCategory.toLowerCase()
  )?.maxScore;

  const scoreOptions = Array.from({ length: maxScore + 1 }).map(
    (_, index) => index
  );

  // handlers for JSX /JS events
  // handler for the onValueChanged event of the Score dropdown
  const onScoreChanged = React.useCallback(({ value }) => setScore(value), []);
  // handler for the "comment" textArea onChange
  const onCommentChange = React.useCallback((e) => {
    setComment(e.target.value);
  }, []);
  // handler for the "edit dimension" button
  const onEditDimensionClicked = React.useCallback(() => {
    submitEdit({ rubricCategory, score, comment });
    onClose();
    setComment("");
  }, [comment, onClose, rubricCategory, score, submitEdit]);
  // handler for clicking the "cancel" button
  const onCancelClicked = React.useCallback(() => {
    setComment("");
    onClose();
  }, [onClose]);

  const ModalBody = (
    <div>
      <p>Existing Grade: {initialScore}</p>
      <CRow className="d-flex flex-row align-items-center">
        <SelectBox
          items={scoreOptions}
          value={score}
          onValueChanged={onScoreChanged}
        />{" "}
        out of {maxScore}
      </CRow>
      <CHeader className="explain-title">
        Please provide a reason for why you are changing the grade. This will be
        used to improve the accuracy of our essay marker.
      </CHeader>
      <CTextarea
        value={comment}
        onChange={onCommentChange}
      />

      <div className="d-flex justify-content-between align-items-center w-50">
        <ActionButton
          className="mt-2 p-2"
          onClick={onEditDimensionClicked}
        >
          Edit dimension
        </ActionButton>
        <Link
          onClick={onCancelClicked}
        >
          Cancel
        </Link>
      </div>
    </div>
  );

  return (
    <CModal show={show} onClose={onClose} centered>
      <CModalHeader className="display-block">
        <CModalTitle>Activity: {prompt.promptName}</CModalTitle>
        <CModalTitle>Edit dimension: {rubricCategory}</CModalTitle>
      </CModalHeader>
      <CModalBody>{ModalBody}</CModalBody>
    </CModal>
  );
};
