import React, { Component } from "react";
import ErrorPage from "views/pages/ErrorPage";

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      isMainContentError: props?.isMainContentError,
    };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error: error?.message });
  }

  render() {
    const { hasError, error, isMainContentError } = this.state;

    // Returns error page for having any error else normal rendering view
    return hasError ? (
      <ErrorPage isMainContentError={isMainContentError} error={error} />
    ) : (
      this.props.children
    );
  }
}
