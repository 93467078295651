import React from "react";
import { useSelector } from "react-redux";
import SchoolLogoName from "./SchoolLogoName";
import ROLES from "utils/UserTypes";

const SchoolHeaderDetail = () => {
  const school = useSelector((state) => state.app.selectedSchool);
  const userGroup = useSelector((state) => state.user.userProfile.userGroup);
  const userType = useSelector((state) => state.user.userProfile.userType);
  const className =
    userGroup === "Admins" &&
    [ROLES.Admin, ROLES.SystemAdmin].includes(userType)
      ? { cursor: "pointer" }
      : null;

  return school ? (
    <div style={className}>
      <SchoolLogoName school={school} />
    </div>
  ) : (
    <div />
  );
};

export default SchoolHeaderDetail;
