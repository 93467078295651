/* Import the Amplify Auth API */
import { Auth, API, graphqlOperation } from "aws-amplify";
import { Analytics } from "aws-amplify";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { getGapAnalysisTestsLocal } from "../../utils/GapAnalysisLocal/gapAnalysisTestsLocal";
import { setupEMTEndPoint } from "../../views/wam/emtService";
import { TestCategoryNames } from "utils/constants";
import {
  setGapFilterTypeAction,
  setGapFilterValueAction,
} from "redux/app/ui.actions";
import { setSelectedActivities } from "redux/wam/wam.actions";
import {
  fetchUserByEmail,
  isLoginValidForStudentClass,
} from "components/user/login/controller/api";
import {
  LAMBDAS_APIS,
  LAMBDAS_API_METHODS,
  filterTypesOptions,
} from "utils/constants";
import LogRocket from "logrocket";
import _ from "lodash";

import history from "../../containers/history";

import userActiontypes from "./user.actiontypes";
import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  setCompletePassword,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  userForgotPasswordFailure,
  userForgotPasswordSuccess,
  userChangePasswordFailure,
  userChangePasswordSuccess,
  emailSignInStart,
  setUserParametersAction,
  signOutStart,
} from "./user.actions";

import {
  setLoadingTrue,
  setLoadingFalse,
  processingFailureAction,
  processingSuccessAction,
  setSelectedSchoolAction,
  setGapAnalysisFilter,
  setUserGapValuesAction,
  runGapAnalysisAction,
  setTestUnitsAction,
} from "../app/ui.actions";

import {
  getUser,
  getUserSettingsByKey,
  listLearningAreas,
  listSystemParameters,
  listTestTypes,
} from "../../graphql/queries";
import { updateUser } from "../../graphql/mutations";
import {
  // getGapAnalysisTests,
  getTeacherClassroomsForFiltering,
} from "../../graphql/bpqueries";
import {
  getSchoolYear,
  mapSystemParameters,
  isLocalhost,
} from "../../utils/helper";
import { fetchTestUnits } from "../../components/common/consumers/testUnitApiConsumer";
import USERTYPES from "../../utils/UserTypes";
import {
  disableUserAccount as disableAccount,
  enableUserAccount as enableAccount,
} from "dataSources/userAccountDisableEnableAPI";

const userTypeList = ["Educator", "Coordinator", "Admin", "SystemAdmin"];

/**
 * Changes error messages to be more user friendly
 * @param {object|string} error - an error object that has a code and message, or a string
 * @param {string} [error.code] - the error code, if it exists
 * @param {string} [error.message] - the error message, if it exists
 * @returns {object} - a possibly-modified error object with a code and message
 */
const overrideErrorMessage = (error) => {
  // override the error message for user not found
  if (error?.code === "UserNotFoundException") {
    return {
      ...error,
      message:
        "We couldn't find your account. Please check your email address carefully, and also ensure that the correct country has been selected at the top of the screen.",
    };
  } else {
    // otherwise, return the error as-is
    return error;
  }
};

const getGapAnalysisFilter = (state) => state.app.gapAnalysisFilter;
const getGapFilterValue = (state) => state.app.gapFilterValue;
const getGapData = (state) => state.app.gapData;
const getSelectedSchool = (state) => state.app.selectedSchool;
const getCurrentUserProfile = (state) => state.user.userProfile;



export function* handleSignInSuccess() {
  // once the user is authorised, we need to re-get the system parameter which
  //  tells us the EMT graphql endpoint (we couldn't do this before without authenticating)
  yield setupEMTEndPoint();
}

export function* getUserInitialGapFilter(user, email, schoolID) {
  // if user is an educator, set filter to first class they have in current year

  if (_.includes(userTypeList, user.userType)) {
    // get first class for current year
    const {
      data: {
        getTeacherClassrooms: { items: classes },
      },
    } = yield API.graphql(
      graphqlOperation(getTeacherClassroomsForFiltering, { email })
    );

    let rec = classes.filter(
      (c) =>
        c.classroom.classType === "Classroom" &&
        c.classroom.schoolYear === getSchoolYear() &&
        c.classroom.schoolID === schoolID
    )[0];

    if (!rec) {
      // check for focus groups if no classrooms
      rec = classes.filter(
        (c) =>
          c.classroom.classType === "FocusGroup" &&
          c.classroom.schoolYear === getSchoolYear() &&
          c.classroom.schoolID === schoolID
      )[0];
    }

    // get learning areas
    const {
      data: {
        listLearningAreas: { items: areas },
      },
    } = yield API.graphql(graphqlOperation(listLearningAreas));

    // get test types
    let {
      data: {
        listTestTypes: { items: types },
      },
    } = yield API.graphql(graphqlOperation(listTestTypes));

    if (window.location.href.indexOf("gapanalysis")) {
      // allow only category 1 for initial load in gapanalysis testtypes
      types = _.chain(types)
        .filter(
          (item) =>
            item?.testCategory?.name.toLowerCase() ===
            TestCategoryNames.CATEGORY_1.toLowerCase()
        )
        .value();
    }

    // get data for filter
    if (rec) {
      // get tests
      const input = {
        classroomID: rec.classroom.id,
        learningAreas: areas.map((a) => a.id),
        testTypes: types.map((t) => t.id),
      };

      const tests = yield getGapAnalysisTestsLocal(input);

      const gapFilter = {
        schoolID: user.userSchoolID,
        email,
        filterType: rec.classroom.classType,
        filterValue: rec.classroom.id,
        learningAreas: areas.map((a) => a.id),
        testTypes: types.map((t) => t.id),
        testKeys: tests.map((t) => t.id),
        tests: tests,
        gapSchools: [],
        gapTitle: `${
          rec.classroom.classType === "Classroom" ? "Class" : "Group"
        }: ${rec.classroom.className}`,
      };

      yield put(setGapAnalysisFilter(gapFilter));
      yield put(setUserGapValuesAction(gapFilter));
      if (
        shouldRunGapAnalysisForUserAndPathname(user, window.location.pathname)
      ) {
        yield put(runGapAnalysisAction());
      }
    } else {
      const currentUser = yield select(getCurrentUserProfile);
      const school = yield select(getSelectedSchool);

      if (window.location.pathname === "/wamreports") {
        const input = {
          email: currentUser.email,
          settingsKey: { eq: `${school.id}#WamReports` },
        };

        let filterData = {};

        try {
          const resp = yield API.graphql(
            graphqlOperation(getUserSettingsByKey, input)
          );

          if (resp.data.getUserSettingsByKey.items.length > 0) {
            filterData = JSON.parse(
              resp.data.getUserSettingsByKey.items[0].settingsData
            );

            yield put(setGapFilterTypeAction(filterData?.filterType));
            yield put(setGapFilterValueAction(filterData?.filterValue));
            yield put(setSelectedActivities(filterData?.selectedActivities));
          } else {
            yield put(setGapFilterTypeAction(null));
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        const gapFilter = {
          schoolID: user.userSchoolID,
          email,
          filterType: null,
          filterValue: null,
          learningAreas: areas.map((a) => a.id),
          testTypes: types.map((t) => t.id),
          testKeys: [],
          tests: [],
          gapSchools: [],
          gapTitle: "Gap Analysis",
        };
        yield put(setUserGapValuesAction(gapFilter));
        yield put(setGapAnalysisFilter(gapFilter));
      }
    }
  }
}

const shouldRunGapAnalysisForUserAndPathname = (user, pathname) => {
  return (
    (!user.userType === USERTYPES.Coordinator && // Coordinators don't get gap analysis on dashboard (or "home" or "login")
      (pathname === "/" ||
        pathname === "/dashboard" ||
        pathname === "/login")) ||
    pathname === "/gapanalysis" // everyone gets it on the gap analysis page
  );
};

export function* getUserProfile(userAuth) {
  try {
    const {
      attributes: { email },
    } = userAuth;

    const {
      data: { getUser: user },
    } = yield API.graphql(graphqlOperation(getUser, { email }));

    if (
      userAuth.signInUserSession !== undefined &&
      userAuth.signInUserSession !== undefined &&
      userAuth.signInUserSession.accessToken !== undefined &&
      userAuth.signInUserSession.accessToken.payload !== undefined
    ) {
      const groups =
        userAuth.signInUserSession.accessToken.payload["cognito:groups"];
      if (!(groups !== undefined && groups.includes("Users"))) {
        //alert('This user is not part of the user group: Users');
        yield put(
          signInFailure("This user is not part of the user group: Users")
        );
        return;
      }
    }
    const gapFilter = yield select(getGapAnalysisFilter);
    const gapFilterValue = yield select(getGapFilterValue);
    const gapData = yield select(getGapData);
    let selectedSchool = yield select(getSelectedSchool);

    if (!selectedSchool) {
      yield put(setSelectedSchoolAction(user.school));
      selectedSchool = user.school;
    }

    if (user.userGroup === "Users" && selectedSchool.id !== user.school.id) {
      yield put(setSelectedSchoolAction(user.school));
      selectedSchool = user.school;
    }

    // set initial gap analysis filter if we can
    if (!_.isEmpty(gapFilter)) {
      let isCohortOrNetwork =
        gapFilter.filterType === "Network" || gapFilter.filterType === "Cohort"
          ? true
          : false;

      const shouldRunGapAnalysis = shouldRunGapAnalysisForUserAndPathname(
        user,
        window.location.pathname
      );
      // filter is set for different user & school?
      if (
        gapFilter.email !== email ||
        gapFilter.schoolID !== selectedSchool.id ||
        !gapFilter.filterType
      ) {
      } else if (!gapFilterValue) {
        yield put(setUserGapValuesAction(gapFilter));
        if (shouldRunGapAnalysis) {
          yield put(runGapAnalysisAction());
        }
      } else if (gapFilterValue && gapData[0] === "EMPTY_RESULT") {
        if (shouldRunGapAnalysis) {
          yield put(runGapAnalysisAction());
        }
      } else if (isCohortOrNetwork) {
      }
    }

    if (process.env.NODE_ENV === "development") {
      console.warn(
        "=== ignore warnings about Redux logger being detected, this should only happen in development ==="
      );
    }

    // complete sign in process
    yield put(signInSuccess(userAuth, user));

    // set parameters for the user
    yield setUserParameters();

    if (!isLocalhost) {
      // don't send logs to LogRocket in development
      LogRocket.identify(user.userId, {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userGroup: user.userGroup,
        userType: user.userType,
        userSchoolID: user.userSchoolID,
        school: user.school.schoolName,
      });
    }

    if (process.env.NODE_ENV !== "development") {
      // update pinpoint endpoint with user information
      const existingCredentials = yield Auth.currentUserCredentials();
      if (existingCredentials?.identityId) {
        // update pinpoint
        try {
          yield Analytics.updateEndpoint({
            userId: existingCredentials.identityId,
            attributes: {
              // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
              cognitoUsername: [userAuth.username],
              cognitoIdentityId: [existingCredentials.identityId],
              userId: [user.userId],
              firstName: [user.firstName],
              lastName: [user.lastName],
              email: [user.email],
              userGroup: [user.userGroup],
              userType: [user.userType],
              userSchoolID: [user.userSchoolID],
              school: [user.school.schoolName],
            },
          });
        } catch (e) {
          console.error("error updating pinpoint for user", e);
        }
      }
    }

    // update last sign in datetime
    const input = {
      email,
      lastSignIn: new Date().toISOString(),
    };
    try {
      yield API.graphql(graphqlOperation(updateUser, { input }));
    } catch (error) {
      console.error("error updating last login", error);
    }
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    let user;
    try {
      user = yield Auth.signIn(email, password);
    } catch (error) {
      // re-throw error with possibly-overridden message
      throw overrideErrorMessage(error);
    }
    const { challengeName } = user;
    const userRecord = yield fetchUserByEmail(email);

    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      yield put(setCompletePassword(user, true));
    } else if (userRecord.isArchived) {
      yield put(signOutStart());
        yield put(
          signInFailure(
            "User is not active."
          )
        );
  } else if (userRecord.userType === filterTypesOptions.STUDENT) {
      // For student, check if has classroom for current year, else prevent from login
      const isLoginValidForStudent =
        userRecord.userType === filterTypesOptions.STUDENT
          ? yield isLoginValidForStudentClass(userRecord.userId)
          : true;
      if (!isLoginValidForStudent) {
        yield put(signOutStart());
        yield put(
          signInFailure(
            "User is not assigned a current classroom, please contact your teacher to be assigned."
          )
        );
      } else {
        yield getUserProfile(user);
        history.push("/");
      }
    } else {
      yield getUserProfile(user);
      history.push("/");
    }
  } catch (error) {
    if (_.toLower(error?.message) === "User is disabled.".toLocaleLowerCase()) {
      error.message = "Login is not enabled.";
    }
    yield put(signInFailure(error.message));
  }
}

export function* setUserNewPassword({ payload: { currentUser, password } }) {
  try {
    yield put(setLoadingTrue("Setting new password..."));
    const user = yield Auth.completeNewPassword(currentUser, password, {
      name: "BP User",
    });
    // user returned from completeNewPassword is somewhat different to signInWithEmail
    user.attributes = { ...user.challengeParam.userAttributes };

    yield getUserProfile(user);
    yield put(setLoadingFalse());
    history.push("/");
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield Auth.currentAuthenticatedUser();
    if (!userAuth) {
      yield put(signInFailure());
      return;
    }
    yield getUserProfile(userAuth);
  } catch (error) {
    yield put(signInFailure());
  }
}
export function* signUserOut({ payload: { schoolID, userType } }) {
  try {
    yield Auth.signOut();
    yield put(signOutSuccess());

    history.replace("/login");
  } catch (error) {
    yield put(signOutFailure(error.message));
  }
}

export function* disableUserAccount({ payload }) {
  try {
    const responseMessage = yield disableAccount({ payload });
    yield put(processingSuccessAction(responseMessage));
  } catch (error) {
    yield put(processingFailureAction(error.message));
  }
}
export function* enableUserAccount({ payload }) {
  try {
    const responseMessage = yield enableAccount({ payload });
    yield put(processingSuccessAction(responseMessage));
  } catch (error) {
    yield put(processingFailureAction(error.message));
  }
}

export function* setUserPassword({ payload }) {
  try {
    const init = {
      body: {
        users: [{ username: payload.username, permanent: payload.permanent }],
        password: payload.password,
        userEmail: payload.email,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(yield Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    yield API.post(
      LAMBDAS_APIS.AdminQueries,
      LAMBDAS_API_METHODS.AdminQueries.SET_USER_PASSWORD,
      init
    );
    yield put(resetUserPasswordSuccess(payload.email));
  } catch (error) {
    console.error("error resetting user password", payload.email, error);
    yield put(resetUserPasswordFailure(payload.email, error.message));
  }
}

export function* changeCurrentUserPassword({
  payload: { oldPassword, newPassword },
}) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    yield Auth.changePassword(user, oldPassword, newPassword);
    yield put(userChangePasswordSuccess());
  } catch (error) {
    yield put(userChangePasswordFailure(error.message));
  }
}

export function* startUserForgotPassword({
  payload: { username, setError, setShowChangePassword },
}) {
  try {
    console.log("ask aws to send a verification code", username);
    yield put(setLoadingTrue("Sending verification code..."));
    yield Auth.forgotPassword(username);
    setShowChangePassword(true);
    yield put(setLoadingFalse());
  } catch (error) {
    let errorMessage = error.message;
    if (error.code === "UserNotFoundException") {
      errorMessage =
        "Email ID entered does not match with our system, Please enter a correct username for getting a verification code to reset your password.";
    }

    if (
      error.code === "NotAuthorizedException" ||
      error?.message ===
        "Temporary password has expired and must be reset by an administrator."
    ) {
      errorMessage =
        "Temporary password has expired and must be reset by an administrator. Please ask your coordinator to reset the password, or email support@elastik.com";
    }

    setError(errorMessage);
    yield put(userForgotPasswordFailure(errorMessage));
    yield put(setLoadingFalse());
  }
}

export function* submitUserForgotPasswordStart({
  payload: { username, code, newPassword, statusFunc, modalFunc },
}) {
  try {
    console.log(
      "sending the information to change the password",
      username,
      code
    );
    yield put(setLoadingTrue("Setting new password..."));
    yield Auth.forgotPasswordSubmit(username, code, newPassword);
    yield put(userForgotPasswordSuccess());
    yield put(emailSignInStart({ email: username, password: newPassword }));
  } catch (error) {
    let errorMessage = error.message;
    errorMessage = errorMessage.replace("client id", "Client ID");
    statusFunc(errorMessage);
    yield put(userForgotPasswordFailure(error.message));
    yield put(setLoadingFalse());
  }
}

export function* setUserParameters() {
  const {
    data: {
      listSystemParameters: { items },
    },
  } = yield API.graphql(graphqlOperation(listSystemParameters));

  const parameters = mapSystemParameters(items);
  const testUnits = yield fetchTestUnits();

  yield put(setUserParametersAction(parameters));
  yield put(setTestUnitsAction(testUnits));
}

export function* onSetUserParameters() {
  yield takeLatest(userActiontypes.SET_USER_PARAMETERS, test);
}

export function* onDisableUserStart() {
  yield takeLatest(userActiontypes.DISABLE_USER_START, disableUserAccount);
}

export function* onEnableUserStart() {
  yield takeLatest(userActiontypes.ENABLE_USER_START, enableUserAccount);
}

export function* onUserChangePasswordStart() {
  yield takeLatest(
    userActiontypes.USER_CHANGE_PASSWORD_START,
    changeCurrentUserPassword
  );
}

export function* onResetUserPassword() {
  yield takeLatest(userActiontypes.RESET_USER_PASSWORD_START, setUserPassword);
}
export function* onEmailSignInStart() {
  yield takeLatest(userActiontypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(userActiontypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(userActiontypes.SIGN_OUT_START, signUserOut);
}

export function* onSetCompletePassword() {
  yield takeLatest(userActiontypes.COMPLETE_USER_PASSWORD, setUserNewPassword);
}

export function* onUserForgotPasswordStart() {
  yield takeLatest(
    userActiontypes.USER_FORGOT_PASSWORD_START,
    startUserForgotPassword
  );
}

export function* onUserForgotPasswordSubmitStart() {
  yield takeLatest(
    userActiontypes.USER_FORGOT_PASSWORD_SUBMIT,
    submitUserForgotPasswordStart
  );
}

export function* onSignInSuccess() {
  // once the user is authorised, we need to re-get the system parameter which
  //  tells us the EMT graphql endpoint (we couldn't do this before without authenticating)

  yield takeLatest(userActiontypes.SIGN_IN_SUCCESS, handleSignInSuccess);
}

export function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSetCompletePassword),
    call(onResetUserPassword),
    call(onUserForgotPasswordStart),
    call(onUserForgotPasswordSubmitStart),
    call(onUserChangePasswordStart),
    call(onDisableUserStart),
    call(onEnableUserStart),
    call(onSignInSuccess),
  ]);
}
