import React from "react";
import { CCol, CContainer, CRow, CImg } from "@coreui/react";
import { useSelector } from "react-redux";
import ROLES from "utils/UserTypes";

const ErrorPage = ({ isMainContentError, error }) => {
  const userType = useSelector((state) => state.user.userProfile.userType);
  return (
    <div className="c-app c-default-layout flex-row align-items-center error-boundary">
      <CContainer className="outer-content-error">
        <CRow className="justify-content-center">
          {!isMainContentError && (
            <CImg
              className="c-sidebar-brand-full"
              src="/Elastik_ID_Blue.png"
              height={45}
            />
          )}
          <CCol md="8">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">Oops!</h1>
              <h4 className="pt-3">Something seems to have gone haywire.</h4>
              <p className="text-muted float-left">
                Please contact your system administrator...
              </p>
              <br />
              {userType === ROLES.SystemAdmin && (
                <p className="code">{error}</p>
              )}
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ErrorPage;
