/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://71ov8390ic.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "AdminTasks",
            "endpoint": "https://j99xnzohnk.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "ClientEmailServiceAPI",
            "endpoint": "https://lbtwq2v3fc.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "EmtServiceApi",
            "endpoint": "https://g5vnddvvge.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "FroalaS3SignatureAPI",
            "endpoint": "https://jiqch470zh.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "GapAnalysisApi",
            "endpoint": "https://16jprzquyb.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "GraphQLQueries",
            "endpoint": "https://89k3g4dg5f.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "Handwriting",
            "endpoint": "https://qz1pguoq45.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "ProcessUndefinedStudentHandwritingEssays",
            "endpoint": "https://2bt5ypadk4.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "QuestionsAPI",
            "endpoint": "https://kuo49n1sl2.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "UserLoginsManagerAPI",
            "endpoint": "https://cibwpc6u87.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "WAMAPI",
            "endpoint": "https://fv6lci6bvg.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "bprestapi",
            "endpoint": "https://bu00ah74ua.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        },
        {
            "name": "mergeStudents",
            "endpoint": "https://eu6id37dbe.execute-api.ap-southeast-2.amazonaws.com/testing",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4nns2qmidzag5kb5kswkyoxovq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-2:e0168a0e-5bda-4b4f-9ed9-d3186f4b6859",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_NTLrrbV6u",
    "aws_user_pools_web_client_id": "5vtfnipt4mbng3a0fib7r977ii",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bpedsysuserstorage180834-testing",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
