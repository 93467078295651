import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CHeader, CHeaderNav } from "@coreui/react";
import { isSchoolCurrentYearRolledOver, getSchoolYear } from "utils/helper";

const SchoolRolledYearWarning = () => {
  const school = useSelector((state) => state.app.selectedSchool);
  const [showWarning, setShowWarning] = useState(false);
  const currentSchoolYear = getSchoolYear();
  const previousSchoolYear = currentSchoolYear - 1;

  useEffect(() => {
    (async () => {
      const isRolledOver = await isSchoolCurrentYearRolledOver(school.id);
      setShowWarning(!isRolledOver);
    })();
  });

  return (
    showWarning && (
      <CHeader className="justify-content-between topbar-warning">
        <CHeaderNav>
          The data presented is currently based on {previousSchoolYear}{" "}
          classlists and data, we are awaiting {currentSchoolYear} classlist
          data from your school.
        </CHeaderNav>
      </CHeader>
    )
  );
};

export default SchoolRolledYearWarning;
