import { useDispatch, useSelector } from "react-redux";
import { setStickyGuide } from "redux/app/ui.actions";

const StickyGuide = () => {
  const dispatch = useDispatch();
  const stickyHelpGuideContent = useSelector(
    (state) => state.app.stickyHelpGuideContent
  );

  const handleCloseStickyGuide = () => {
    dispatch(setStickyGuide(null));
  };

  if (!stickyHelpGuideContent) return false;

  return (
    <div className="sticky-guide">
      <div
        onClick={() => handleCloseStickyGuide()}
        aria-hidden="true"
        role="button"
        className="dx-link dx-link-edit dx-icon-close dx-link-icon close"
      />

      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe // skipcq JS-D010 - we don't want a sandbox attribute here
        src={stickyHelpGuideContent}
        width="100%"
        height="100%"
        allowfullscreen
        frameBorder="0"
        title="StickyHelpGuide"
      />
    </div>
  );
};

export default StickyGuide;
