import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer } from "@coreui/react";
import { useSelector } from "react-redux";

// routes config
import routes from "../routes";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { ROUTE_CONDITION_PARAMETER_MAP_KEYS } from "utils/constants";

const TheContent = () => {
  const userType = useSelector((state) => state.user.userProfile.userType);
  const growthbook = useGrowthBook(); // used for feature flag checking of route configuration
  // passed as a parameter to each nav item's "condition" function for runtime determination of whether to include the
  // nav item; currently only feature flags are evaluated.
  const navConditionParameterMap = { [ROUTE_CONDITION_PARAMETER_MAP_KEYS.GROWTHBOOK_INSTANCE]: growthbook };

  return (
    <main className="c-main">
      <CContainer fluid style={{ height: "100%", position: "relative" }}>
        <Switch>
          {routes
            .filter((route) => route.group.includes(userType))
            .filter((route) => {
              if (Object.prototype.hasOwnProperty.call(route, "condition")) {
                // evaluate the condition if there is one.
                const conditionEvaluator = route.condition;
                return conditionEvaluator(navConditionParameterMap);
              }
              return true; // the route is unconditional
            })
            .map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);
