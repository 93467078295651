import React from "react";
import dayjs from "dayjs";
import ROLES from "../utils/UserTypes";
import LogRocket from "logrocket";
import { isLocalhost } from "../utils/helper";

// load the UTC plugin for dayjs
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const copyLogRocketSessionToClipboard = (e, url) => {
  e.preventDefault();
  if (!isLocalhost) {
    LogRocket.getSessionURL((sessionURL) => {
      navigator.clipboard
        .writeText(sessionURL)
        .then(() => {
          console.log(
            `LogRocket session URL copied to clipboard: ${sessionURL}`
          );
        })
        .catch((err) => {
          console.log(
            `Error copying LogRocket session URL to clipboard: ${err}`
          );
        });
    });
  }
  if (url) {
    // opening GitHub link
    // the code below opens the GitHub URL in a new TAB rather than a new window
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: url,
      rel: "noopener,noreferrer",
    }).click();
  }
};

const TheHeaderVersionInfo = (props) => {
  const { gitSHA, gitCommitTimestamp, userRole } = props;

  if (gitCommitTimestamp) {
    try {
      // version is a timestamp in seconds since the epoch UTC. Change it to milliseconds.
      const dateSecs = Number.parseInt(gitCommitTimestamp);
      const dateMillis = dateSecs * 1000;
      const versionDayJs = dayjs(dateMillis);
      if (ROLES.SystemAdmin === userRole) {
        // If we're a sysadmin, hotlink to browse the tree as at the git commit
        const url = `https://github.com/best-performance/bp-edsys/tree/${gitSHA}`;
        return (
          <p className="userInfo" onClick={(e) => {copyLogRocketSessionToClipboard(e, url)}}>
            <span className="versionNumberFromGit">
              {versionDayJs.utc().format("vM.D.H.m")}
            </span>
            <span className="gitSHA">({gitSHA})</span>
          </p>
        );
      } else {
        return (
          <p className="userInfo"  onClick={(e) => {copyLogRocketSessionToClipboard(e)}}>
            <span className="versionNumberFromGit">
              {versionDayJs.utc().format("vM.D.H.m")}
            </span>
            <span className="gitSHA">({gitSHA})</span>
          </p>
        );
      }
    } catch (e) {
      console.log(
        "Couldn't parse version number as Date for env var REACT_APP_GIT_COMMIT_TIMESTAMP:",
        gitCommitTimestamp
      );
    }
  }
  return <></>;
};

export default TheHeaderVersionInfo;
