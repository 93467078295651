import { API, graphqlOperation } from "aws-amplify";
import _unionBy from "lodash/unionBy";
import {
  // getGapAnalysisTests,
  listApplicationFeatureFlagGlobals,
  getFeatureFlagSchoolBySchoolID,
} from "../graphql/bpqueries";

/**
 * Currently this loads feature flags from BestPerformance's system and
 * returns an object suitable for consumption by GrowthBook's client library.
 * In future we may want to load feature flags from GrowthBook's system instead.
 * @param {*} user
 * @param {*} selectedSchool
 * @returns {Promise<{object}>} - an object with feature flags suitable for consumption by GrowthBook's client library.
 */
async function loadFeatureFlagsForUserAndSchool(user, selectedSchool) {
  try {
    let globalFeatureFlags = [],
      schoolFeatureFlagOverrides = [];

    const schoolID = selectedSchool?.id || user?.userSchoolID;
    const responseGlobalFlags = await API.graphql(
      graphqlOperation(listApplicationFeatureFlagGlobals, { limit: 1000 })
    );

    if (
      responseGlobalFlags.data.listApplicationFeatureFlagGlobals.items.length >
      0
    ) {
      globalFeatureFlags =
        responseGlobalFlags.data.listApplicationFeatureFlagGlobals.items;
    }

    const responseSchoolFlags = await API.graphql(
      graphqlOperation(getFeatureFlagSchoolBySchoolID, { schoolID })
    );

    if (responseSchoolFlags.data.getFeatureFlagSchoolBySchoolID) {
      schoolFeatureFlagOverrides =
        responseSchoolFlags.data.getFeatureFlagSchoolBySchoolID.items;
    }

    // override global flags with school flags, based on the featureKey property.
    //  _.unionBy gives preference to the first array, so we want to put the school flags first since they override the global flags.
    const mergedFeatureFlagsArray = _unionBy(
      schoolFeatureFlagOverrides,
      globalFeatureFlags,
      "featureKey"
    );

    // mergedFeatureFlagsArray is an array of objects, each object has a featureKey property.
    // We want to convert this to an object with the featureKey as the key, and the object as the value
    // so that we can use it in the feature flag provider (growthbook)
    const mergedFeatureFlagsObject = mergedFeatureFlagsArray.reduce(
      (acc, flagValueObject) => {
        switch (flagValueObject?.applicationFeature?.featureValueType) {
          case "BOOLEAN":
            acc[flagValueObject.featureKey] = {
              defaultValue:
                String(flagValueObject.featureValue).toLowerCase() === "true",
            };
            break;
          case "NUMBER":
            acc[flagValueObject.featureKey] = {
              defaultValue: Number(flagValueObject.featureValue),
            };
            break;
          case "JSON": // JSON is a string, so we need to parse it
            acc[flagValueObject.featureKey] = JSON.parse(
              flagValueObject.featureValue
            );
            break;
          case "STRING": // string and default are the same operation
          default:
            acc[flagValueObject.featureKey] =
              flagValueObject.featureValue;
            break;
        }

        return acc;
      },
      {} // initial value of the accumulator
    );
    return mergedFeatureFlagsObject;
  } catch (error) {
    console.error("error getting feature flags", error);
  }
}

/**
 * A pure function which evaluates a feature flag via dependency injection. The configured Growthbook feature flag state
 * cannot be easily accessed via the Growthbook API or SDK, except via the `GrowthBookProvider` context from within a JSX
 * render function (via react hooks or JSX) . This is a utility function which just performs feature flag evaluation in
 * a stateless manner, delegating to GrowthBook's `isOn` method.
 * @param {import("@growthbook/growthbook-react").GrowthBook} growthbookInstance - a GrowthBook instance that has
 * already been configured with the feature flags and the current user attributes
 * @param {string} featureFlagName - the name of the feature flag to evaluate
 * @returns {boolean} - the result of the feature flag evaluation.
 */
export const queryIsFeatureOn = (growthbookInstance, featureFlagName) => {
  if (!growthbookInstance || !featureFlagName) {
    console.error("isFeatureFlagOnQuery: invalid parameters", growthbookInstance, featureFlagName);
    console.trace();
    return false;
  }
  return growthbookInstance.isOn(featureFlagName);
}

export default loadFeatureFlagsForUserAndSchool;
