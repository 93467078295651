import React from "react";
import { Button } from "devextreme-react";
import _isString from "lodash/isString";

/**
 * Wrapper for a simple DevExtreme button with sensible defaults
 * @param {import("devextreme-react/button").IButtonOptions} props
 * @returns {Button}
 */
const ActionButton = (props) => {
  // if a text prop has been passed in, we'll pass it through as the 'text' prop of <Button> and it will
  //   become the aria-text attribute value.
  let ariaText = props?.text;

  // if a text prop has *NOT* been specified, and the props.children is a string (which will appear as the
  //  button text), then we'll also use that string as the aria-text attribute value.
  if (!ariaText && _isString(props?.children)) {
    ariaText = props.children;
  }
  return (
    <Button
      className="p-2"
      stylingMode="contained"
      type="default"
      color="primary"
      {...props}
      // override the text prop
      text={ariaText ?? ""}
    >
      {props.children}
    </Button>
  );
};

export default ActionButton;
