import React from "react"; 
import { useDispatch, useSelector } from "react-redux"; 
import { useHistory, useLocation } from "react-router-dom"; 
import { Popup } from "devextreme-react"; 
import DataGrid, { 
  Column, 
  FilterRow, 
  LoadPanel, 
  Scrolling, 
  Selection, 
} from "devextreme-react/data-grid"; 
import { SchoolsDataStore } from "../../dataSources/schools/SchoolsDataStore"; 
import { clearGapSettingsAction, setSelectedSchoolAction } from "../../redux/app/ui.actions"; 
import { fetchUsersettingsByKey, handleUpdateUserSettings } from "components/wam/wamAnalytics/api"; 
 
const remoteOps = { 
  filtering: true, 
  paging: false, 
}; 
const filterOpts = ["startsWith", "="]; 
 
const getUsersettingsByKey = async (schoolID, email) => {
  const input = {
    email,
    settingsKey: { eq: `${schoolID}#GapAnalysisFilter` },
  };
  return await fetchUsersettingsByKey(input);
}
 
const handleUpdateUserSettingsData = async (schoolID, email) => {
  const userSetting = await getUsersettingsByKey(schoolID, email);
  if (userSetting?.items?.length > 0) {
    const payload = {
      email,
      schoolID,
      filterType:null,
      filterValue:null,
      learningAreas:[],
      testTypes:[],
      testKeys:[],
      tests:[],
      networkSchools:[],
      gapTitle:null,
    };
    
    const input = {
      id: userSetting.items[0].id,
      settingsData: JSON.stringify(payload),
    };
    
    await handleUpdateUserSettings(input);
  }
}

const SchoolSelector = ({ showForm, closeForm }) => { 
  const dispatch = useDispatch(); 
  const history = useHistory(); 
  const location = useLocation(); 
  const email = useSelector((state) => state.user.userProfile.email);
  async function handleSelectSchool(e) { 
    dispatch(setSelectedSchoolAction(e.data)); 
    closeForm(false); 
 
    // Reset user setting data on changing school
    await handleUpdateUserSettingsData(e.data.id, email);
    dispatch(clearGapSettingsAction());
    if ( 
      location.pathname.startsWith("/schools/") || 
      location.pathname.startsWith("/student/") 
    ) { 
      history.replace(`/schools/${e.data.schoolName}`); 
    } 
  } 
 
  function handleHideForm() { 
    closeForm(false); 
  } 
 
  return ( 
    <Popup 
      closeOnOutsideClick={false} 
      dragEnabled={false} 
      height={400} 
      width={700} 
      onHiding={handleHideForm} 
      showCloseButton 
      showTitle 
      title="Select school" 
      visible={showForm} 
    > 
      <DataGrid 
        id="school-grid" 
        columnAutoWidth 
        dataSource={SchoolsDataStore} 
        elementAttr={{ 
          id: "gridContainer", 
        }} 
        height={300} 
        hoverStateEnabled 
        onRowDblClick={handleSelectSchool} 
        remoteOperations={remoteOps} 
        repaintChangesOnly 
        rowAlternationEnabled 
        showColumnLines={false} 
        showRowLines={false} 
        showBorders={false} 
      > 
        <LoadPanel enabled text="Retrieving schools..." width={400} /> 
        <Scrolling mode="infinite" /> 
        <Selection mode="single" /> 
        <FilterRow visible applyFilter="auto" /> 
        <Column 
          dataField="schoolName" 
          caption="School" 
          filterOperations={filterOpts} 
        /> 
      </DataGrid> 
    </Popup> 
  ); 
}; 
 
export default SchoolSelector; 
