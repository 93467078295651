import _, { isArray } from "lodash";

export const getFilter = (data) => {
  let filter = {};
  if (data) {
    if (!_.isArray(data[0])) {
      filter = data[0]
        ? { [data[0]]: { [getFilterType(data[1])]: data[2] } }
        : data;
    } else {
      data.forEach((d) => {
        if (_.isObject(d) && !_.isArray(d)) {
          for (const [key, value] of Object.entries(d)) {
            filter[key] = value;
          }
        } else {
          if (_.isArray(d)) {
            if (_.isArray(d[0])) {
              for (const entry of d) {
                if (_.isArray(entry) && entry.length > 0) {
                  let entryKey = entry[0];
                  if (entry[0] !== null || entry[0] !== undefined) {
                    if (isArray(entry[0])) {
                      if (typeof entry[0][0] === "function") continue;

                      entryKey =
                        entry[0][0].split(".").length > 1
                          ? entry[0][0].split(".")[1]
                          : entry[0][0];
                    } else {
                      entryKey =
                        entry[0].split(".").length > 1
                          ? entry[0].split(".")[1]
                          : entry[0];
                    }
                  }
                  if (entryKey) {
                    filter[entryKey] = {
                      [getFilterType(entry[1])]: entry[2],
                    };
                  }
                }
              }
            } else {
              if (d[0]) {
                filter[d[0].split(".").length > 1 ? d[0].split(".")[1] : d[0]] =
                  {
                    [getFilterType(d[1])]: d[2],
                  };
              }
            }
          } else {
            if (!_.isString(d)) {
              filter[d[0]] = { [getFilterType(d[1])]: d[2] };
            }
          }
        }
      });
    }
  }
  return filter;
};

function getFilterType(data) {
  switch (data) {
    case "=":
      return "eq";

    case "<>":
      return "ne";

    case "startswith":
      return "beginsWith";

    default:
      return data;
  }
}
