import { useQuery, useMutation, useQueryClient } from "react-query";

import axios from "axios";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { getSystemParameter } from "../../graphql/queries";
// import { emt } from "../../dataSources/systemadmin/SystemParametersDataStore"

// export const jwtStorage = {
//     token: ""
// }

// THis needs to be enabled when a proxy is set up for local development
// https://create-react-app.dev/docs/proxying-api-requests-in-development/
// const emt = axios.create({
//     baseURL: process.env.REACT_APP_EMT_API_BASE_URL
//     // baseURL: "http://localhost:8080"
// })

// emt.interceptors.request.use((config) => {
//     const jwt = localStorage.getItem("token");
//     if (jwt) {
//         config.headers['Authorization'] = `Bearer ${jwt}`
//     }
//     return config
// })

let emt;

const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const setupEMTEndPoint = async () => {
  const input = {
    key: "REACT_APP_EMT_API_BASE_URL",
  };
  API.graphql(graphqlOperation(getSystemParameter, input))
    .then((resp) => {
      const {
        data: {
          getSystemParameter: { paramData },
        },
      } = resp;
      console.log(`setting EMT endpoint URL to ${paramData}`);

      emt = axios.create({
        baseURL: paramData,
      });

      emt.interceptors.request.use(async (config) => {
        const jwt = await getAccessJwtToken();
        if (jwt) {
          config.headers["Authorization"] = `Bearer ${jwt}`;
        }
        return config;
      });
    })
    .catch((e) => {
      console.warn(
        "System Parameter REACT_APP_EMT_API_BASE_URL couldn't be retrieved;"
      );
      console.warn(e);
    });
};

export const getEssaysByStudent = async (studentId) => {
  try {
    const response = await emt.get(`/essay/all/${studentId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getActivityMark = async (activityId) => {
  const response = await emt.get(`/mark/activity/${activityId}`);
  return response.data;
};

export const getAllEssays = async ({ activityId, classroomId }) => {
  const response = await emt.get(`/essay/all/${classroomId}/${activityId}`);

  return response.data;
};

export const getEssay = async (essayId) => {
  const response = await emt.get(`/essay/${essayId}`);
  return response.data;
};

export const deleteEssay = async (essayId) => {
  const response = await emt.delete(`/essay/${essayId}`);
  return response.data;
};

export const updateEssay = async (essayId, data) => {
  const response = await emt.put(`/essay/${essayId}`, data);
  return response.data;
};

export const updateEssayMark = async ({
  essayMarkId,
  rubricCategory,
  score,
  scorerId,
  comment,
}) => {
  const response = await emt.put(`/mark/${essayMarkId}`, {
    rubricCategory,
    score,
    scorerId,
    comment,
  });
  return response.data;
};

export const confirmEssayMark = async (essayMarkId) => {
  const response = await emt.post(`/mark/confirm/${essayMarkId}`);
  return response.data;
};

export const createEssay = async (data) => {
  const response = await emt.post("/essay/", data);
  return response.data;
};
export const submitEssay = async (essayId) => {
  const response = await emt.post(`/essay/submit/${essayId}`);
  return response.data;
};

export const submitAllEssays = async (activityId) => {
  const response = await emt.post(`/essay/submit/all/${activityId}`);
  return response.data;
};

export const getEssayMark = async (essayId) => {
  const response = await emt.get(`/mark/essay/${essayId}`);
  return response.data;
};

export const updateMarkComment = async ({ essayMarkId, teacherComment }) => {
  const response = await emt.put(`/mark/comment/${essayMarkId}`, {
    teacherComment,
  });
  return response.data;
};

export const getRubricScoresForActivity = async (activityId) => {
  const response = await emt.get(
    `mark/activity/${activityId}?get_marks_for_rubric_grid=true`
  );
  return response.data;
};

export const QUERY_KEYS = {
  GET_ESSAY_MARK: "GET_ESSAY_MARK",
  GET_ALL_ESSAYS: "GET_ALL_ESSAYS",
  GET_ESSAY: "GET_ESSAY",
  GET_ESSAYS_BY_STUDENT: "GET_ESSAYS_BY_STUDENT",
  GET_RUBRIC_SCORES_FOR_ACTIVITY: "GET_RUBRIC_SCORES_FOR_ACTIVITY",
};

export const useGetEssay = (essayId, options) =>
  useQuery([QUERY_KEYS.GET_ESSAY, essayId], () => getEssay(essayId), options);

export const useGetAllEssays = (params, options) =>
  useQuery(
    [QUERY_KEYS.GET_ALL_ESSAYS, params],
    () => getAllEssays(params),
    options
  );

export const useGetEssaysByStudent = (params, options) =>
  useQuery(
    [QUERY_KEYS.GET_ESSAYS_BY_STUDENT, params],
    () => getEssaysByStudent(params),
    options
  );

export const useGetEssayMark = (essayId, options) =>
  useQuery(
    [QUERY_KEYS.GET_ESSAY_MARK, essayId],
    () => getEssayMark(essayId),
    options
  );
export const useRubricScoresForActivity = (activityId, options) =>
  useQuery(
    [QUERY_KEYS.GET_RUBRIC_SCORES_FOR_ACTIVITY, activityId],
    () => getRubricScoresForActivity(activityId),
    options
  );

export const useUpdateEssay = () =>
  useMutation(({ essayId, body }) => updateEssay(essayId, body));

export const useSubmitEssay = () => {
  const queryClient = useQueryClient();
  return useMutation(submitEssay, {
    onSuccess: (data, essayId) => {
      queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_ESSAYS);
      queryClient.invalidateQueries(QUERY_KEYS.GET_ESSAYS_BY_STUDENT);
      queryClient.invalidateQueries([QUERY_KEYS.GET_ESSAY, essayId]);
      queryClient.invalidateQueries([QUERY_KEYS.GET_ESSAY_MARK, essayId]);
      let counts = 0;
      const interval = setInterval(() => {
        counts += 1;
        queryClient.refetchQueries(QUERY_KEYS.GET_ESSAYS_BY_STUDENT);
        queryClient.refetchQueries(QUERY_KEYS.GET_ALL_ESSAYS);
        queryClient.refetchQueries([QUERY_KEYS.GET_ESSAY, essayId]);
        queryClient.refetchQueries([QUERY_KEYS.GET_ESSAY_MARK, essayId]);
        if (counts === 3) {
          clearInterval(interval);
        }
      }, 3000);
    },
  });
};

export const useSubmitAllEssays = () => {
  const queryClient = useQueryClient();
  return useMutation((activityId) => submitAllEssays(activityId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_ESSAYS);
      setTimeout(() => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_ESSAYS);
      }, 3000);
    },
  });
};

export const useUpdateEssayMark = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ essayMarkId, rubricCategory, score, scorerId, comment }) =>
      updateEssayMark({
        essayMarkId,
        rubricCategory,
        score,
        scorerId,
        comment,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ESSAY_MARK);
      },
    }
  );
};

export const useConfirmEssayMark = () => {
  const queryClient = useQueryClient();
  return useMutation(({ essayMarkId }) => confirmEssayMark(essayMarkId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.GET_ESSAY);
      queryClient.invalidateQueries([QUERY_KEYS.GET_ESSAY_MARK]);
    },
  });
};

export const useCreateEssay = () =>
  useMutation(
    ({ classroomId, activityId, studentId, schoolId, taskType, promptName }) =>
      createEssay({
        classroomId,
        activityId,
        studentId,
        schoolId,
        taskDetails: {
          taskType,
          essayPrompt: promptName,
        },
      })
  );

export const useUpdateComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ essayMarkId, teacherComment }) =>
      updateMarkComment({ essayMarkId, teacherComment }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ESSAY_MARK);
      },
    }
  );
};

export const useDeleteEssay = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteEssay, {
    onSuccess: (data, essayId) => {
      queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_ESSAYS);
      queryClient.invalidateQueries([QUERY_KEYS.GET_ESSAY, essayId]);
    },
  });
};
