import React, { useState } from "react";
import { CCardBody, CCardText, CCard, CCardTitle } from "@coreui/react";
import { useSelector } from "react-redux";
import { CModal, CModalBody, CModalHeader } from "@coreui/react";
import _ from "lodash";
// import Lottie from "lottie-react";
import UserTypes from "utils/UserTypes";
import ActionButton from "../../ActionButton";
import { LoadIndicator } from 'devextreme-react/load-indicator';
//Animation json
// import Animated1 from "./animation/lf30_pSQ3W3.json";
// import Animated2 from "./animation/lf20_7xeqfabo.json";
// import Animated3 from "./animation/lf20_N3IbTTWRWt.json";
// import Animated4 from "./animation/lf20_eIphcV4GJ1.json";
// import Animated5 from "./animation/lf20_7htpyk2w.json";
// import Animated6 from "./animation/lf20_xwgclkyh.json";
// import Animated7 from "./animation/lf20_kwozkvd1.json";
// import Animated8 from "./animation/lf20_DMgKk1.json";
// import Animated9 from "./animation/lf30_TBKozE.json";
// import Animated10 from "./animation/lf20_8QyKOkeQV8.json";

// const rubricAnimationMap = {
//   audience: Animated1,
//   "character-and-setting": Animated2,
//   cohesion: Animated3,
//   ideas: Animated4,
//   paragraphing: Animated5,
//   punctuation: Animated6,
//   "sentence-structure": Animated7,
//   spelling: Animated8,
//   "text-structure": Animated9,
//   vocabulary: Animated10,
// };

function formatRubricName(category) {
  if (!category) return "";
  return category.trim().toLowerCase().replaceAll(" ", "-");
}

export const GradeLineItem = ({
  chatGptResponse,
  rubricCategory,
  progression,
  score,
  setEditGrade,
  setInfoGrade,
  comment,
  maxScore,
  isEssayCompleted,
  isPrinting
}) => {
  const userType = useSelector((state) => state.user.userProfile.userType);
  const rurbricKey = formatRubricName(rubricCategory);
  const processingMessage = useSelector((state) => state.app.processingMessage);
  const [isModalProgressShow, setIsModalProgressShow] = useState(false);
  const progressionStr =
     chatGptResponse?.[rurbricKey]
      ? chatGptResponse[rurbricKey]
      : progression;

  // JSX / JS event handlers
  const infoGradeButtonHandler = React.useCallback(
    () => setInfoGrade(true),
    [setInfoGrade]
  );
  const editLinkClickHandler = React.useCallback(
    () => setEditGrade(),
    [setEditGrade]
  );
  const generalProgressionButtonHandler = React.useCallback(
    () => setIsModalProgressShow(true),
    [setIsModalProgressShow]
  );

  const generalProgressionModalOnCloseHandler = React.useCallback(
    () => setIsModalProgressShow(false),
    []
  );

  return (
    <>
      <CCard className="mb-0 d-flex flex-row p-3 gradeLineItem">
        <CCardBody className="mb-0 d-flex flex-row">
          <div className="d-flex w-100">
            <div className="mr-4 w-100">
              <CCardTitle className="content">
                {rubricCategory}: {score} / {maxScore}
                {setInfoGrade && (
                  <i
                    className="dx-icon-info icon-format"
                    role="button"
                    tabIndex={0}
                    onClick={infoGradeButtonHandler}
                    onKeyUp={infoGradeButtonHandler}
                   />
                )}
                {setEditGrade &&
                  !isEssayCompleted &&
                  (userType === "Coordinator" || userType === "Educator") && (
                    <span
                      className="editLink"
                      role="link"
                      tabIndex={0}
                      onClick={editLinkClickHandler}
                      onKeyUp={editLinkClickHandler}
                    >
                      Edit
                    </span>
                  )}
              </CCardTitle>
              <CCardText>{comment}</CCardText>
            </div>
            <div className="w-100 card-progression-container">
              <CCardTitle>Progression</CCardTitle>

              { processingMessage && <>
              <LoadIndicator style={{marginBottom: '-6px'}} id="small-indicator" height={20} width={20} /> {processingMessage}
              </>}

              <CCardText>{progressionStr}</CCardText>
              { !isPrinting && chatGptResponse?.[rurbricKey] && (
                <span className="general-progression-container">
                  {userType !== UserTypes.Student && (
                    <ActionButton
                      className="general-progression-btn"
                      onClick={generalProgressionButtonHandler}
                      disabled={!progression || _.trim(progression) === ""}
                    >
                      General Progression
                    </ActionButton>
                  )}
                </span>
              )}
            </div>
          </div>
        </CCardBody>
      </CCard>
      <CModal
        show={isModalProgressShow}
        onClose={generalProgressionModalOnCloseHandler}
        centered
      >
        <CModalHeader>General Progression</CModalHeader>
        <CModalBody>{progression}</CModalBody>
      </CModal>
    </>
  );
};
