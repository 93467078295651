/**
 * User roles, also known as "userType". This is stored in the database against the User record, and is *not* directly
 * related to `userGroup` (i.e. `Admins` or `Users`, which refers to a Cognito user group).
 * @readonly
 * @enum {string}
 */
const ROLES = {
  Admin: "Admin",
  Coordinator: "Coordinator",
  DeputyPrincipal: "DeputyPrincipal",
  Educator: "Educator",
  Parent: "Parent",
  Principal: "Principal",
  SystemAdmin: "SystemAdmin",
  Student: "Student",
  AQA: "AQA",
  DataDeletion: "DataDeletion"
};

/**
 * @typedef {("ADMINISTRATION" | "COORDINATION" | "EDUCATION")} RoleGroupType
 */

/**
 * A "grouping" of *some* of the ROLES according to the type of tasks performed. Each ROLE member should appear in only
 * one group.
 * @type {Object.<RoleGroupType, ROLES[]>}
 * @example
 * {
 *  "ADMINISTRATION": [
 *    "SystemAdmin",
 *    "Admin"
 *  ],
 *  "COORDINATION": [
 *    "Coordinator",
 *    "DeputyPrincipal",
 *    "Principal"
 *  ],
 *  "EDUCATION": [
 *    "Educator"
 *  ]
 * }
 */
export const PERMISSIONS = {
  ADMINISTRATION: [ROLES.SystemAdmin, ROLES.Admin],
  COORDINATION: [ROLES.Coordinator, ROLES.DeputyPrincipal, ROLES.Principal],
  EDUCATION: [ROLES.Educator],
};

/**
 * Array specifying a hierarchy of roles, with each role in the array being superior to all of other the higher-indexed
 * roles (i.e. the array is in sequence from most-privileged to least-privileged) Note that a role might not be a
 * member, e.g. where there is a special non-hierarchical view (such as with parents, or students, or AQA).
 * This could be reimplemented as a tree.
 * @type {ROLES[]}
 */
export const ROLES_HIERARCHY = [
  ROLES.Admin,
  ROLES.SystemAdmin,
  ROLES.Principal,
  ROLES.DeputyPrincipal,
  ROLES.Coordinator,
  ROLES.Educator,
];

/**
 * A map of role names to the array of role names that should have less than or equal the permissions of the key
 * @type {Object<ROLES, ROLES[]>}
 * @example
 * {
 *  "Admin": [
 *    "SystemAdmin",
 *    "Admin",
 *    "Coordinator",
 *    "DeputyPrincipal",
 *    "Principal",
 *    "Educator"
 *  ],
 *  "Coordinator": [
 *    "Coordinator",
 *    "DeputyPrincipal",
 *    "Principal",
 *    "Educator"
 *  ],
 *  "DeputyPrincipal": [
 *    "Coordinator",
 *    "DeputyPrincipal",
 *    "Principal",
 *    "Educator"
 *  ],
 *  "Educator": [
 *    "Educator"
 *  ],
 *  "Principal": [
 *    "Coordinator",
 *    "DeputyPrincipal",
 *    "Principal",
 *    "Educator"
 *  ],
 *  "SystemAdmin": [
 *    "SystemAdmin",
 *    "Admin",
 *    "Coordinator",
 *    "DeputyPrincipal",
 *    "Principal",
 *    "Educator"
 *  ]
 * }
 */
export const PERMISSIONS_INHERITED = Object.keys(ROLES)
  .map((role) => {
    const thisRoleIndexInHierarchy = ROLES_HIERARCHY.indexOf(role);
    if (thisRoleIndexInHierarchy === -1) return { [role]: [role] }; // only itself
    // find all the roles in the hierarchy AFTER this one
    const rolesWithFewerPermissions = ROLES_HIERARCHY.slice(
      thisRoleIndexInHierarchy
    ); // should include this role
    return {
      [role]: rolesWithFewerPermissions,
    };
  })
  .reduce((acc, cur) => ({ ...acc, ...cur }), {});

/**
 * Returns true if the user has at least the status of the comparison role in the hierarchy.
 * @param {ROLES} userRole - the role we want to check i.e. the user's role
 * @param {ROLES} comparisonRole - the role we want to compare against i.e. the role we want to check the user against
 * @returns {boolean} - whether the `userRole` is at least at the level of `comparisonRole` in the hierarchy
 */
export function isRoleEqualOrSuperiorToRole(userRole, comparisonRole) {
  if (!userRole || !comparisonRole) {
    return false;
  }
  const comparisonRoleIndex = ROLES_HIERARCHY.indexOf(comparisonRole);
  const userRoleIndex = ROLES_HIERARCHY.indexOf(userRole);
  if (comparisonRoleIndex === -1 || userRoleIndex === -1) {
    console.error(
      `one of either "${comparisonRole}" or "${userRole}" was not found in the hierarchy: ${JSON.stringify(
        ROLES_HIERARCHY,
        null,
        2
      )}`
    );
    console.trace?.(); // conditional invocation just in case "console" has been clobbered by a logger which has no 'trace' method.
    return false;
  }
  return userRoleIndex <= comparisonRoleIndex;
}

export default ROLES;
