import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CHeader, CHeaderNav } from "@coreui/react";

const HeaderWarning = () => {
  const [showWarning, setShowWarning] = useState(false);
  const message = useSelector((state) => state.app.headerWarningMessage);

  useEffect(() => {
    if (message) {
      setShowWarning(true);
      return;
    }
    setShowWarning(false);
  }, [message]);

  return (
    showWarning && (
      <CHeader className="justify-content-between topbar-warning">
        <CHeaderNav>{message}</CHeaderNav>
      </CHeader>
    )
  );
};

export default HeaderWarning;
