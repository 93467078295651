import { API, graphqlOperation } from "aws-amplify";
import { getUserSettingsByKey } from "graphql/queries";
import * as queries from "./queries";
import { getStudentsByClassroom } from "views/wam/queries";
import {
  createUserSettingsData,
  updateUserSettingsData,
} from "graphql/mutations";

export async function fetchUsersettingsByKey(input) {
  try {
    const resp = await API.graphql(
      graphqlOperation(getUserSettingsByKey, input)
    );

    return resp.data.getUserSettingsByKey;
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
}

export async function fetchSchoolClassroomsByYearLevel(payload) {
  try {
    const query = queries.getSchoolClassroomsByYearLevel;
    const queryName = "getSchoolClassroomsByYearLevel";

    return fetchAllNextTokenData(queryName, query, payload);
  } catch (error) {
    console.error(error);
  }
}

export async function fetchClassroomStudent(payload) {
  try {
    const results = await API.graphql(
      graphqlOperation(getStudentsByClassroom, payload)
    );
    return results?.data?.getStudentsByClassroom?.items || [];
  } catch (error) {
    console.error(error);
  }
}

async function fetchAllNextTokenData(queryName, query, input) {
  let nextToken = null;
  let data = [];

  do {
    input.nextToken = nextToken;
    let searchResults = await API.graphql(graphqlOperation(query, input));

    data = [...data, ...searchResults.data[queryName].items];

    nextToken = searchResults.data[queryName].nextToken;
  } while (nextToken != null);

  return data;
}

export async function handleUpdateUserSettings(input) {
  try {
    const response = await API.graphql(
      graphqlOperation(updateUserSettingsData, { input })
    );
    return response;
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
}

export async function handleCreateUserSettings(input) {
  try {
    const response = await API.graphql(
      graphqlOperation(createUserSettingsData, { input })
    );
    return response;
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
}
