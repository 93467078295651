import UserActions from "./user.actiontypes";
import notify from "devextreme/ui/notify";

const initialState = {
  currentUser: null,
  userProfile: { userGroup: "Users", avatar: null },
  error: "",
  userParams: {
    env: "",
    question_pool_bottom: 3,
    question_pool_top: 60,
    question_auto_selection_percentage: 20,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UserActions.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: payload.user,
        userProfile: payload.profile,
        error: "",
        completeNewPassword: false,
      };

    case UserActions.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        userProfile: { userGroup: "Users", avatar: null },
      };

    case UserActions.SIGN_IN_FAILURE:
    case UserActions.SIGN_OUT_FAILURE:
    case UserActions.SIGN_UP_FAILURE:
      return {
        ...state,
        currentUser: null,
        userProfile: { userGroup: "Users", avatar: null },
        error: payload,
        completeNewPassword: false,
      };

    case UserActions.SET_COMPLETE_PASSWORD:
      return {
        ...state,
        completeNewPassword: payload.completeNewPassword,
        currentUser: payload.currentUser,
        error: "",
      };

    case UserActions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload.user,
        userProfile: { userGroup: "Users", avatar: null },
        completeNewPassword: false,
        error: "",
      };

    case UserActions.SET_USER_PARAMETERS:
      if (!payload || payload === {}) return state;

      return {
        ...state,
        userParams: {
          ...initialState.userParams,
          ...payload,
        },
      };

    case UserActions.RESET_USER_PASSWORD_FAILURE:
      notify({
        message: `Error resetting user ${payload.username} password: ${payload.error}`,
        type: "error",
        displayTime: 3000,
        position: "top",
      });
      return state;

    case UserActions.RESET_USER_PASSWORD_SUCCESS:
      notify({
        message: `Password reset for user: ${payload}`,
        type: "success",
        displayTime: 3000,
        position: "top",
      });
      return state;

    case UserActions.USER_FORGOT_PASSWORD_FAILURE:
      return state;

    case UserActions.USER_FORGOT_PASSWORD_SUCCESS:
      notify({
        message: "Your password has been reset, signing in...",
        type: "success",
        displayTime: 2000,
        position: "top",
      });
      return state;

    case UserActions.USER_CHANGE_PASSWORD_FAILURE:
      notify({
        message: `Error changing password: ${payload}`,
        type: "error",
        displayTime: 3000,
        position: "top",
      });
      return state;

    case UserActions.USER_CHANGE_PASSWORD_SUCCESS:
      notify({
        message: "Your Password has been changed",
        type: "success",
        displayTime: 1500,
        position: "top",
      });
      return state;

    default:
      return state;
  }
};
