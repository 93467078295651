import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import LogRocket from "logrocket";
import { isLocalhost } from "../utils/helper";

import logger from "redux-logger";

import rootReducer from "./rootReducer";
import rootSaga from "./root-saga";
import actionTypes from "./user/user.actiontypes";

/** @type {boolean} - Whether the current environment is a dev env */
const isDevEnvironment =
  process.env.NODE_ENV === "development" || isLocalhost;

const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware];

if (isDevEnvironment) {
  // if we're in dev, add the logger middleware
  middleWares.push(logger);
} else {
  // use the LogRocket redux middleware in production
  middleWares.push(
    LogRocket.reduxMiddleware({
      actionSanitizer: function (action) {
        // don't send any actions with type EMAIL_SIGN_IN_START to LogRocket as they contain the user's password
        if (action.type === actionTypes.EMAIL_SIGN_IN_START) {
          return null;
        }
        return action;
      },
    })
  );
}

export const store = createStore(
  rootReducer,
  isDevEnvironment
    ? composeWithDevTools(applyMiddleware(...middleWares))
    : applyMiddleware(...middleWares)
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default { store, persistor };
