import React, { useCallback, useEffect, useState } from "react"; 
import { 
  CBadge, 
  CDropdown, 
  CDropdownItem, 
  CDropdownMenu, 
  CDropdownToggle, 
} from "@coreui/react"; 
import CIcon from "@coreui/icons-react"; 
import { useHistory } from "react-router-dom"; 
import { API, graphqlOperation } from "aws-amplify"; 
import { getNotificationsByRecipient } from "../graphql/queries"; 
import { useDispatch, useSelector } from "react-redux"; 
import { 
  onNotificationByRecipient, 
  onNotificationByRecipientUpdate, 
  onNotificationByRecipientDelete, 
} from "../graphql/subscriptions"; 
import { markNotificationAsReadAction } from "../redux/app/ui.actions"; 
import { Popover } from "devextreme-react"; 
import notify from "devextreme/ui/notify"; 
 
const getMessageType = (item) => { 
  switch (item) { 
    case "TestResult": 
      return "cil-graph"; 
 
    case "Schedule": 
      return "cil-clock"; 
 
    default: 
      return "cil-bell"; 
  } 
}; 
 
const getDisplayClass = (item) => { 
  switch (item) { 
    case "TestResult": 
      return "text-success"; 
 
    case "Schedule": 
      return "text-danger"; 
 
    default: 
      return "text-success"; 
  } 
}; 
 
const animationConfig = { 
  show: { 
    type: "pop", 
    from: { 
      scale: 0, 
    }, 
    to: { 
      scale: 0.9, 
    }, 
  }, 
  hide: { 
    type: "fade", 
    from: 1, 
    to: 0, 
  }, 
}; 
 
const hideConfig = { delay: 1000 }; 
 
const TheHeaderDropdownNotif = () => { 
  const history = useHistory(); 
  const dispatch = useDispatch(); 
  const [showPopover, setShowPopover] = useState(false); 
 
  const userProfile = useSelector((state) => state.user.userProfile); 
  const currentUser = useSelector((state) => state.user.currentUser); 
  const [notifications, setNotifications] = useState([]); 
 
  function handleViewNotifications() { 
    history.push("/notifications"); 
  } 
 
  const getNotifications = useCallback(() => { 
    const input = { 
      recipient: userProfile.email, 
      sortDirection: "DESC", 
      filter: { read: { eq: false } }, 
    }; 
 
    API.graphql(graphqlOperation(getNotificationsByRecipient, input)) 
      .then((resp) => { 
        const { 
          data: { 
            getNotificationsByRecipient: { items }, 
          }, 
        } = resp; 
        setNotifications(items); 
      }) 
      .catch((error) => 
        console.error("error getting notifications", input, error) 
      ); 
  }, [userProfile]); 
 
  function handlePopoverShown() { 
    setTimeout(() => { 
      setShowPopover(false); 
    }, 3000); 
  } 
 
  useEffect(() => { 
    let newSubs, updSubs, delSubs; 
 
    if (userProfile.email) { 
      getNotifications(); 
 
      if (currentUser) { 
        newSubs = API.graphql({ 
          query: onNotificationByRecipient, 
          variables: { 
            recipient: userProfile.email, 
          }, 
        }); 
        newSubs.subscribe({ 
          next: ({ 
            value: { 
              data: { onNotificationByRecipient }, 
            }, 
          }) => { 
            setNotifications((prev) => { 
              return [...prev, onNotificationByRecipient]; 
            }); 
            setShowPopover(true); 
          }, 
          error: (error) => console.error("new notification subs error", error), 
        }); 
        updSubs = API.graphql({ 
          query: onNotificationByRecipientUpdate, 
          variables: { 
            recipient: userProfile.email, 
          }, 
        }); 
        updSubs.subscribe({ 
          next: ({ 
            value: { 
              data: { onNotificationByRecipientUpdate }, 
            }, 
          }) => { 
            if (onNotificationByRecipientUpdate.readDate) { 
              setNotifications((prev) => { 
                return [ 
                  ...prev.filter( 
                    (i) => i.id !== onNotificationByRecipientUpdate.id 
                  ), 
                ]; 
              }); 
            } else { 
              setNotifications((prev) => { 
                return [ 
                  ...prev.filter( 
                    (i) => i.id !== onNotificationByRecipientUpdate.id 
                  ), 
                  onNotificationByRecipientUpdate, 
                ]; 
              }); 
              setShowPopover(true); 
            } 
          }, 
          error: (error) => 
            console.error("update notification subs error", error), 
        }); 
        delSubs = API.graphql({ 
          query: onNotificationByRecipientDelete, 
          variables: { 
            recipient: userProfile.email, 
          }, 
        }); 
        delSubs.subscribe({ 
          next: ({ 
            value: { 
              data: { onNotificationByRecipientDelete }, 
            }, 
          }) => { 
            setNotifications((prev) => { 
              return [ 
                ...prev.filter( 
                  (i) => i.id !== onNotificationByRecipientDelete.id 
                ), 
              ]; 
            }); 
          }, 
          error: (error) => 
            console.error("delete notification subs error", error), 
        }); 
      } 
    } 
    return () => { 
      try { 
        if (currentUser) { 
          newSubs.unsubscribe(); 
          updSubs.unsubscribe(); 
          delSubs.unsubscribe(); 
        } 
      } catch (error) {} 
    }; 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [userProfile]); 
 
  function handleMarkAsRead(item) { 
    dispatch(markNotificationAsReadAction(item)); 
    setNotifications((prev) => { 
      return prev.filter((i) => i.id !== item.id); 
    }); 
  } 

  function navigateToNotifications(e) { 
    if (e.type === "TestResult") { 
      history.push("/gapanalysis"); 
    } else if (e.type === "TestShared") { 
      history.push("/assessments"); 
    } else if (e.type === "PromptShared") { 
      history.push("/wam"); 
    } else { 
      history.push("/notifications"); 
    } 
 
    handleMarkAsRead(e); 
  } 
 
  return ( 
    <div> 
      <CDropdown inNav className="c-header-nav-item mx-2"> 
        <CDropdownToggle 
          className="c-header-nav-link" 
          caret={false} 
          onClick={() => setShowPopover(false)} 
        > 
          <CIcon id="theBell" name="cil-bell" /> 
          {notifications.length !== 0 && ( 
            <CBadge shape="pill" color="danger"> 
              {notifications.length} 
            </CBadge> 
          )} 
          <Popover 
            target="#theBell" 
            position="bottom" 
            visible={showPopover} 
            animation={animationConfig} 
            closeOnOutsideClick 
            height={50} 
            hideEvent={hideConfig} 
            onShown={handlePopoverShown} 
          > 
            You have new notifications 
          </Popover> 
        </CDropdownToggle> 
        <CDropdownMenu placement="bottom-end" className="pt-0"> 
          <CDropdownItem header tag="div" className="text-center" color="light"> 
            <strong>You have {notifications.length} notifications</strong> 
          </CDropdownItem> 
          {notifications.map((item) => { 
            // Getting the payload of the notification. 
            const payload = JSON.parse(item.notificationPayload); 
            const link = payload?.signedURL; 
            return ( 
              <CDropdownItem key={item.id}> 
                <CIcon 
                  name={getMessageType(item.type)} 
                  className={`${getDisplayClass(item.type)} mr-2`} 
                /> 
                <div 
                  className="header-notification" 
                  onClick={() => navigateToNotifications(item)} 
                > 
                  {item.message} 
                </div> 
                {link && ( 
                  <u 
                    onClick={() => { 
                      // If there's a link to download the payload of passwords. 
                      const request = new XMLHttpRequest(); 
                      // Checking that the signed URL from S3 works and if so, it downloads the file. Otherwise, notify the user that the link is expired. 
                      request.onreadystatechange = function () { 
                        if (request.readyState === 4) { 
                          const response = request.status; //this contains the status code 
                          if (response === 400) { 
                            notify( 
                              "The passwords file is expired, please reset the passwords again.", 
                              "error", 
                              "4000" 
                            ); 
                          } else { 
                            window.open(link); 
                          } 
                        } 
                      }; 
                      request.open("GET", link, true); 
                      request.send(null); 
                    }} 
                    style={{ 
                      color: "blue", 
                      fontSize: "15px", 
                    }} 
                    className="nav-link" 
                  > 
                    Download Your passwords 
                  </u> 
                )} 
                <CIcon 
                  name="cil-check" 
                  className="ml-5" 
                  onClick={() => handleMarkAsRead(item)} 
                /> 
              </CDropdownItem> 
            ); 
          })} 
          <CDropdownItem 
            className="text-center border-top" 
            onClick={handleViewNotifications} 
          > 
            <strong>View all notifications</strong> 
          </CDropdownItem> 
        </CDropdownMenu> 
      </CDropdown> 
    </div> 
  ); 
}; 
 
export default TheHeaderDropdownNotif; 
