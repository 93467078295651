export const formatStudentName = (student) => {
  if (!student) {
    return "";
  }
  const { firstName, middleName, lastName } = student;
  return `${firstName}${middleName ? ` ${middleName}` : ""} ${lastName}`;
};
export const formatPromptTitle = ({ promptName, taskType }) =>
  `${promptName} ${taskType ? `(${taskType})` : ""}`;

export const teacherViews = {
  viewEssay: "viewEssay",
};

export const getProgressComment = (rubrics, rubricCategory, score) =>
  rubrics
    ?.find(
      (rubric) =>
        rubric.rubricName.toLowerCase() === rubricCategory.toLowerCase()
    )
    ?.progressComments.items.find(
      (progressions) => progressions.score === score
    )?.progressComment;

export const convertToPascalCase = (s) => {
  if (!s) return s;

  return s.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};
