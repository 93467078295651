export default {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_USER_PARAMETERS: "SET_USER_PARAMETERS",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_OUT_START: "SIGN_OUT_START",
  SET_COMPLETE_PASSWORD: "SET_COMPLETE_PASSWORD",
  COMPLETE_USER_PASSWORD: "COMPLETE_USER_PASSWORD",
  RESET_USER_PASSWORD_START: "RESET_USER_PASSWORD_START",
  RESET_USER_PASSWORD_FAILURE: "RESET_USER_PASSWORD_FAILURE",
  RESET_USER_PASSWORD_SUCCESS: "RESET_USER_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_START: "USER_FORGOT_PASSWORD_START",
  USER_FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_SUBMIT: "USER_FORGOT_PASSWORD_SUBMIT",
  USER_CHANGE_PASSWORD_START: "USER_CHANGE_PASSWORD_START",
  USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
  USER_CHANGE_PASSWORD_FAILURE: "USER_CHANGE_PASSWORD_FAILURE",
  DISABLE_USER_START: "DISABLE_USER_START",
  ENABLE_USER_START: "ENABLE_USER_START",
};
