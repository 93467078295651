import { routeConditionByFeatureFlagFactory } from "utils/helper";
import { FEATURE_FLAGS, MenuUrls } from "../utils/constants";

/**
 * @typedef {Object} ElastikNavigationItem - an item which will be rendered in the app's navigation (currently in
 * `TheSidebar`). Should really be defined in a common structure with the routes to avoid duplication.
 * @property {string} name - The text to display
 * @property {string} to - The route to which the user should navigate; must correspond to a route defined in `routes.js`
 * @property {string} fontIcon - The font icon to display
 * @property {string} dataCy - The data-cy attribute to use for the element (used for cypress testing)
 * @property {string[]} [role] - The role(s) to which the user must belong in order to see the item
 * @property {ElastikNavigationItem[]} [_children] - sub-menu items, just like this one.
 * @property {string} [_tag] - The react JSX component to use for rendering this item.
 * @property {RouteConditionFunction} [condition] - a function which determines at runtime whether the navigationItem should be included
 */

/** @type {ElastikNavigationItem[]} */
const navRoutes = [
  {
    // format from dev
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    fontIcon: "fas fa-home",
    "data-cy": "nav-dashboard",
  },
  {
    role: ["Student"],
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/studentDashboard",
    fontIcon: "fas fa-home",
    "data-cy": "nav-student-dashboard",
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Gap Analysis",
    to: "/gapanalysis",
    fontIcon: "fas fa-chart-bar",
    "data-cy": "nav-gap-analysis",
  },
  {
    role: ["Admin", "SystemAdmin", "Coordinator", "Student", "Educator"],
    _tag: "CSidebarNavDropdown",
    name: "Writemark",
    fontIcon: "fas fa-edit",
    "data-cy": "nav-writing-assessment-new",
    _children: [
      {
        // New WAM
        role: ["Admin", "SystemAdmin", "Educator", "Coordinator"],
        _tag: "CSidebarNavItem",
        name: "Dashboard",
        to: "/wamDashboard",
        fontIcon: "fas fa-desktop",
        "data-cy": "nav-writing-assessment-new-dashboard",
      },
      {
        // New WAM
        role: ["Admin", "SystemAdmin", "Student", "Educator", "Coordinator"],
        _tag: "CSidebarNavItem",
        name: "Writing Activities",
        to: "/wam",
        fontIcon: "fas fa-edit",
        "data-cy": "nav-writing-assessment-new-writing-activities",
      },
      {
        role: ["Admin", "SystemAdmin", "Coordinator", "Educator"],
        _tag: "CSidebarNavItem",
        name: "Reports",
        to: "/wamreports", // TODO: make an ist route (routes.js) - done
        fontIcon: "fas fa-chart-bar", //
        "data-cy": "nav-writing-assessment-new-reports",
      },
      {
        role: ["Coordinator", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Licence History",
        to: MenuUrls.WAM_NEWREQUEST,
        fontIcon: "fas fa-list",
        "data-cy": "nav-writing-assessment-new-licence-history",
      },
      {
        role: ["Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Licenced Schools",
        to: MenuUrls.WAM_SCHOOLS,
        fontIcon: "fas fa-list",
        "data-cy": "nav-writing-assessment-new-licenced-schools",
      },

      {
        role: ["Admin", "SystemAdmin", "Coordinator"],
        _tag: "CSidebarNavItem",
        name: "Licence management",
        to: MenuUrls.WAM_STUDENT_LICENCE_MANAGE,
        fontIcon: "fas fa-table",
        "data-cy": "nav-writing-assessment-new-licence-management",
      },
      // {
      //   role: ["Admin", "SystemAdmin", "Coordinator"],
      //   _tag: "CSidebarNavItem",
      //   name: "WAM Help",
      //   to: "/wam/help",
      //   fontIcon: "fas fa-question-circle",
      // },
    ],
  },
  {
    // Individaul Student tracking (BC)
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Progression",
    to: "/progression", // TODO: make an ist route (routes.js) - done
    fontIcon: "fas fa-chart-line", // TODO: get graph icon -done
    "data-cy": "nav-progression",
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Marks Book",
    to: "/marksbook",
    fontIcon: "fas fa-book-open",
    "data-cy": "nav-marks-book",
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Reviews",
    to: "/assessments",
    fontIcon: "fas fa-table",
    "data-cy": "nav-reviews",
  },
  // {
  //   role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
  //   _tag: "CSidebarNavItem",
  //   name: "Proficiency",
  //   to: "/proficiency",
  //   fontIcon: "fas fa-chart-line",
  // },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Lesson Guides",
    to: "/lesson-guides",
    fontIcon: "fas fa-images",
    "data-cy": "nav-lesson-guides",
    condition: routeConditionByFeatureFlagFactory(FEATURE_FLAGS.HIDE_LESSON_GUIDES, true), // hide if the "hide lesson guides" feature flag is set
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Focus Groups",
    to: "/focusgroups",
    fontIcon: "fas fa-address-card",
    "data-cy": "nav-focus-groups",
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Classrooms",
    to: "/classrooms",
    fontIcon: "fas fa-school",
    "data-cy": "nav-classrooms",
  },
  {
    role: ["Admin", "SystemAdmin", "Coordinator", "Educator"],
    _tag: "CSidebarNavItem",
    name: "Schools",
    to: "/schools",
    fontIcon: "fas fa-university",
    "data-cy": "nav-schools",
  },
  {
    role: ["Users"],
    _tag: "CSidebarNavItem",
    name: "School",
    to: "/schools",
    fontIcon: "fas fa-university",
    "data-cy": "nav-school",
  },
  {
    role: ["Educator", "Coordinator", "Student", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Online Assessment",
    to: "/writingassessment",
    fontIcon: "fas fa-pen-alt",
    "data-cy": "nav-online-assessment",
  },
  {
    // format from dev
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Help Guide",
    to: "/helpguide",
    fontIcon: "fas fa-question-circle",
    "data-cy": "nav-help-guide",
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavDropdown",
    name: "Curriculum",
    fontIcon: "fas fa-graduation-cap",
    "data-cy": "nav-curriculum",
    _children: [
      {
        // format from dev
        role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Codes",
        to: "/accodes",
        fontIcon: "fas fa-school",
        "data-cy": "nav-curriculum-ac-codes",
      },
      {
        role: ["SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "EAL/D Progress Maps",
        to: "/ealdprogressmaps",
        fontIcon: "fas fa-scroll",
        "data-cy": "nav-curriculum-eald-progress-maps",
      },
      {
        role: ["Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Year Levels",
        to: "/yearlevels",
        fontIcon: "fas fa-briefcase",
        "data-cy": "nav-curriculum-year-levels",
      },
      {
        role: ["Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Learning Areas",
        to: "/learningareas",
        fontIcon: "fas fa-user-graduate",
        "data-cy": "nav-curriculum-learning-areas",
      },
      {
        role: ["Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Strands & Substrands",
        to: "/strands",
        fontIcon: "fas fa-layer-group",
        "data-cy": "nav-curriculum-strands",
      },
    ],
  },
  {
    role: ["SystemAdmin"],
    _tag: "CSidebarNavDropdown",
    name: "Content Manager",
    fontIcon: "fas fa-tools",
    "data-cy": "nav-content-manager",
    _children: [
      {
        // format from Dev
        role: ["SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Question Bank",
        to: "/questionbank2",
        fontIcon: "fas fa-tasks",
        "data-cy": "nav-content-manager-question-bank",
      },
      {
        role: ["SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Booklet",
        to: "/booklet/list",
        fontIcon: "fas fa-user-shield",
        "data-cy": "nav-content-manager-booklet",
      },
    ],
  },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Question Bank",
    to: "/questionbank",
    fontIcon: "fas fa-question-circle",
    "data-cy": "nav-question-bank",
  },
  {
    role: ["Coordinator", "Admin", "SystemAdmin", "DataDeletion"],
    _tag: "CSidebarNavDropdown",
    name: "Admin",
    fontIcon: "fas fa-tools",
    "data-cy": "nav-admin",
    _children: [
      {
        // format from Dev
        role: ["Admin", "SystemAdmin", "DataDeletion"],
        _tag: "CSidebarNavItem",
        name: "Tests",
        to: "/testadmin",
        fontIcon: "fas fa-tasks",
        "data-cy": "nav-admin-tests",
      },
      {
        role: ["Coordinator", "Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Users",
        to: "/users",
        fontIcon: "fas fa-user-shield",
        "data-cy": "nav-admin-users",
      },
      {
        role: ["Admin", "SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "System Admin",
        to: "/sysadmin",
        fontIcon: "fas fa-cogs",
        "data-cy": "nav-admin-system-admin",
      },
      {
        role: ["SystemAdmin"],
        _tag: "CSidebarNavItem",
        name: "Merge Students",
        to: "/mergedstudent",
        fontIcon: "fas fa-list",
        "data-cy": "nav-admin-merge-students",
      },
      // {
      //   role: ["Admin", "SystemAdmin"],
      //   _tag: "CSidebarNavItem",
      //   name: "Marks book",
      //   to: "/marksbook",
      //   fontIcon: "fas fa-cogs",
      // },
    ],
  },

  // {
  //   role: ["Admin", "SystemAdmin", "Student"],
  //   _tag: "CSidebarNavDropdown",
  //   name: "Writing Assessment Module",
  //   fontIcon: "fas fa-edit",
  //   _children: [
  //     {
  //       // New WAM
  //       role: ["Admin", "SystemAdmin", "Student"],
  //       _tag: "CSidebarNavItem",
  //       name: "Essay Marking",
  //       to: "/wam", // TODO: make an ist route (routes.js) - done
  //       fontIcon: "fas fa-edit", // TODO: get graph icon -done
  //     },
  //   ],
  // },
  {
    role: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    _tag: "CSidebarNavItem",
    name: "Notifications",
    to: "/notifications",
    fontIcon: "fas fa-bell",
    "data-cy": "nav-notifications",
  },
];

export default navRoutes;
