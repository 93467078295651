// This is the form component shown / hidden by the "Message Us" floating action button;
//  it submits the form data to a lambda, which sends the email.
import React, { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  EmailRule,
  StringLengthRule,
} from "devextreme-react/form";
import LogRocket from "logrocket";
import { useSelector } from "react-redux";
import { LoadPanel } from "devextreme-react";
import { getCurrentRegion, isLocalhost, salesforceWebToLeadFormSubmit } from "utils/helper";
import { NAMES_FOR_AWS_REGIONS } from "utils/Regions";

const SALESFORCE_ORG_ID =
  process.env.REACT_APP_MESSAGE_US_WIDGET_SALESFORCE_ORG_ID;

const thisRegionName = NAMES_FOR_AWS_REGIONS[getCurrentRegion()];

export const MessageUsFormComponent = (props) => {
  const userProfile = useSelector((state) => state?.user?.userProfile);
  const [formData, setFormData] = useState({
    name: userProfile?.firstName
      ? `${userProfile.firstName} ${userProfile.lastName}`
      : "",
    email: userProfile?.email || "",
    company: userProfile?.school?.schoolName || "",
    description: "",
  });
  const [logRocketURL, setLogRocketURL] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isLocalhost) {
      LogRocket.getSessionURL((sessionURL) => {
        setLogRocketURL(sessionURL);
      });
    }
  }, []);

  const handleSubmit = (submitEvent) => {
    submitEvent.preventDefault();
    // submit the form.
    const payload = {
      ...formData,
      orgid: SALESFORCE_ORG_ID,
      retURL: "",
      origin: "Mobile App",
      description: formData.description,
      subject: `Web Contact${formData.name ? " From " + formData.name : ""}${
        formData.company ? " (" + formData.company + ")" : ""
      }`,
      "00NI8000000OFgr": thisRegionName,
    };
    if (logRocketURL) {
      payload["00NI8000000OFgs"] = logRocketURL;
    }

    try {
      console.log("posting...", payload);
      salesforceWebToLeadFormSubmit(payload);
      notify(
        {
          message: "You have sent us an email.",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
      setFormData({}); // clear the form.
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      notify(
        {
          message: "Sorry, there was an error",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "error",
        3000
      );
    }
    return false;
  };

  const submitButtonOptions = {
    text: "Send email",
    useSubmitBehavior: true,
    type: "default",
    name: "submit",
    value: "submit",
  };

  return (
    <form action="#" onSubmit={handleSubmit}>
      <p>{"Send us an email and we'll get back to you ASAP"}</p>
      <Form
        colCount={1}
        id="MessageUsForm"
        formData={formData}
        disabled={isSubmitting}
      >
        <SimpleItem
          label={{ text: "Contact Name" }}
          dataField="name"
          editorType="dxTextBox"
        >
          <RequiredRule message="Contact Name is required" />
          <StringLengthRule max={80} />
        </SimpleItem>
        <SimpleItem dataField="email" editorType="dxTextBox">
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <StringLengthRule max={80} />
        </SimpleItem>
        <SimpleItem
          dataField="company"
          editorType="dxTextBox"
          label={{ text: "School" }}
        >
          <RequiredRule message="School is required" />
          <StringLengthRule max={80} />
        </SimpleItem>
        <SimpleItem
          dataField="type"
          label={{ text: "Type of Request" }}
          editorType="dxSelectBox"
          editorOptions={{
            items: [
              "Login or Password Reset",
              "Add Users",
              "Content",
              "Data Upload",
              "Enhancement Request",
              "Technical Issue",
              "Pilot Program",
              "Other",
            ],
            showClearButton: true,
          }}
        />
        <SimpleItem
          dataField="description"
          label={{ text: "Message" }}
          editorType="dxTextArea"
          editorOptions={{ height: "8em" }}
        >
          <RequiredRule message="Message is required" />
        </SimpleItem>

        <ButtonItem
          buttonOptions={submitButtonOptions}
          horizontalAlignment="left"
        />
      </Form>

      <LoadPanel
        container={"#MessageUsForm"}
        position={{ of: "#MessageUsForm" }}
        visible={isSubmitting}
        message={"Sending..."}
        showPane
      />
    </form>
  );
};
