import React from "react";
import { CLink } from "@coreui/react";

const Link = (props) => (
  <CLink {...props} className="link">
    {props.children}
  </CLink>
);

export default Link;
