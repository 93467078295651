import React from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
export const RubricGridTab = ({ rubric, essayMarks }) => {
  const progressCommentsByName = rubric?.reduce((acc, item) => {
    const rubricName = item.rubric.rubricName;
    return {
      ...acc,
      [rubricName]: {
        dimensions: rubricName,
        ...acc[rubricName],
        [String(item.score)]: item.progressComment,
        score: Object.values(essayMarks.mark).find(
          (x) => x.rubricCategory.toLowerCase() === rubricName.toLowerCase()
        )?.score,
      },
    };
  }, {});

  const gridData = Object.entries(progressCommentsByName)
    ?.sort((item1, item2) => item1[0].localeCompare(item2[0]))
    .map(([_, value]) => value);
  //   ?.map((i) => ({
  //     id: i.rubric.id,
  //     dimensions: i.rubric.rubricName,
  //     progressComments: i.rubric.progressComments.items.map(
  //       (x) => x.progressComment
  //     ),
  //     score: Object.values(essayMarks.mark).find(
  //       (x) =>
  //         x.rubricCategory.toLowerCase() === i.rubric.rubricName.toLowerCase()
  //     )?.score,
  //   }))
  //   .map((x) => ({ ...x, ...x.progressComments }));

  // const unique = Array.from(new Set(dgData.map(JSON.stringify))).map(
  //   JSON.parse
  // );
  function renderCell(props) {
    const score = props.data.score;
    const column = props.column.dataField;
    const match = score === Number(column);
    return (
      <div
        style={{ ...(match && { backgroundColor: "#00ABE9" }) }}
        className={`rubricText ${!props.value ? "rubricText-null" : ""}`}
      >
        {props.value}
      </div>
    );
  }
  return (
    <div>
      <DataGrid dataSource={gridData} keyExpr="dimensions" showBorders>
        <Column dataField="dimensions" />
        <Column dataField="0" cellRender={renderCell} />
        <Column dataField="1" cellRender={renderCell} />
        <Column dataField="2" cellRender={renderCell} />
        <Column dataField="3" cellRender={renderCell} />
        <Column dataField="4" cellRender={renderCell} />
        <Column dataField="5" cellRender={renderCell} />
        <Column dataField="6" cellRender={renderCell} />
      </DataGrid>
    </div>
  );
};
