import { API, graphqlOperation, Storage } from "aws-amplify";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import CustomStore from "devextreme/data/custom_store";
import { Buffer } from "buffer";

import awsConfig from "../../aws-exports";

import { getFilter } from "../../graphql/utils";

import { createSchool, updateSchool } from "../../graphql/bpmutations";
import {
  getSchool,
  getSchoolsByNetwork,
  getSchoolsSorted,
} from "../../graphql/bpqueries";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = awsConfig;

export const SchoolsDataStore = new CustomStore({
  key: "id",
  loadMode: "processed",
  errorHandler: (error) => {
    console.error(error);
  },
  byKey: (key) => {
    const input = { id: key };
    return new Promise((resolve, reject) => {
      API.graphql(graphqlOperation(getSchool, input))
        .then((resp) => {
          return resp.data.getSchool;
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  load: (options) => {
    const input = {
      dummy: "school",
      limit: 10000,
      sortDirection: "ASC",
    };

    if (options.skip !== 0) {
      input.nextToken = options.userData ? options.userData.nextToken : null;
    }

    let query = getSchoolsSorted;
    let queryName = "getSchoolsSorted";
    let filter = null;

    if (options.filter) {
      filter = getFilter(options.filter);

      if (filter.networkID) {
        query = getSchoolsByNetwork;
        queryName = "getSchoolsByNetwork";
        input.networkID = filter.networkID.eq;
        delete filter.networkID;
        delete input.dummy;
      }

      if (filter.schoolName) {
        if (filter.schoolName.startsWith) {
          input.schoolName = {
            beginsWith: _.startCase(filter.schoolName.startsWith),
          };
        } else {
          input.schoolName = {
            eq: filter.schoolName.eq,
          };
        }
        delete filter.schoolName;
      }

      if (!_.isEmpty(filter)) {
        input.filter = filter;
      }
    }

    if (options.searchValue && queryName === "getSchoolsSorted") {
      input.schoolName = {
        beginsWith: _.startCase(options.searchValue),
      };
    }

    return API.graphql(graphqlOperation(query, input))
      .then(({ data }) => {
        const { items } = data[queryName];

        options.data = items;
        options.totalCount = items.length;

        if (options.userData) {
          options.userData.nextToken = data[queryName].nextToken;
        }

        return options;
      })
      .catch((error) => {
        console.error("error getting schools", error);
      });
  },
  insert: async (values) => {
    values.schoolName = _.trim(values.schoolName);
    values.dummy = "school";

    if(values.isAnIsolatedAndDistanceEducationSchool){
      values.isAnIsolatedAndDistanceEducationSchool = 1
    }else{
      values.isAnIsolatedAndDistanceEducationSchool = 0
    }

    if (!values.studentLoginEnabled) {
      values.studentLoginEnabled = false;
    }

    if (!values.ealdProgress) {
      values.ealdProgress = false;
    }

    let imgObject = null;

    if (values.logo) {
      const imgData = new Buffer.from(
        values.logo.split("base64,")[1],
        "base64"
      );
      const imageKey = `images/schools/${uuid()}.jpg`;
      imgObject = {
        bucket,
        region,
        key: imageKey,
      };
      values.logo = imgObject;
      await Storage.put(imageKey, imgData, {
        contentType: "image/jpg",
        contentEncoding: "base64",
      }).catch((error) => console.error(error));
    }

    const input = { ...values };
    return API.graphql(graphqlOperation(createSchool, { input })).catch(
      (error) => console.error(error)
    );
  },
  update: async (key, values) => {
    values.dummy = "school";

    if (!values.studentLoginEnabled) {
      values.studentLoginEnabled = false;
    }

    if (!values.ealdProgress) {
      values.ealdProgress = false;
    }

    if(values.isAnIsolatedAndDistanceEducationSchool){
      values.isAnIsolatedAndDistanceEducationSchool = 1
    }else{
      values.isAnIsolatedAndDistanceEducationSchool = 0
    }

    if (values.logo) {
      const imgData = new Buffer.from(
        values.logo.split("base64,")[1],
        "base64"
      );
      const imageKey = `images/schools/${uuid()}.jpg`;
      const imgObject = {
        bucket,
        region,
        key: imageKey,
      };
      values.logo = imgObject;
      await Storage.put(imageKey, imgData, {
        contentType: "image/jpg",
        contentEncoding: "base64",
      });

      if (values.oldLogo?.key) {
        await Storage.remove(values.oldLogo.key);
      }
    }

    delete values.oldLogo;

    const input = { id: key, ...values };
    return new Promise((resolve, reject) => {
      API.graphql(graphqlOperation(updateSchool, { input }))
        .then((resp) => {
          return resp.data;
        })
        .then((data) => resolve(data.updateSchool))
        .catch((error) => {
          console.error("error updating school", error);
          reject(error.errors[0].message);
        });
    });
  },
  remove: (key) => {
    return new Promise((resolve, reject) => {
      reject("Function still to be implemented");
    });
  },
});
