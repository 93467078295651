import React, { useState, useMemo } from "react";
import TitleRow from "../../TitleRow";
import ActionButton from "../../ActionButton";
import {
  CCard,
  CModal,
  CModalBody,
  CModalHeader,
  CTextarea,
  CRow,
} from "@coreui/react";
import { Tabs } from "devextreme-react/tabs";
import { RubricGridTab } from "../../RubricGridTab";
import Link from "../../../../../components/common/Link";
import { useUpdateComment, useUpdateEssayMark } from "../../../emtService";
import { EditGrade } from "./EditGrade";
import { useSelector } from "react-redux";
import { GradeLineItem } from "./GradeLineItem";
import {
  useGetRubricProgressCommentsByTaskType,
  useRubricsByTaskType,
} from "../../../queries";
import { getProgressComment } from "../../../utils";

const tabs = [{ text: "List view" }, { text: "Rubric" }];

export const AmplifyTaskType = {
  PERSUASIVE: "Persuasive",
  NARRATIVE: "Narrative",
};

export const EssayMarks = ({
  chatGptResponse,
  prompt,
  essay,
  essayMarks,
  isEssayCompleted,
}) => {
  const userId = useSelector((state) => state.user.userProfile.userId);
  const userType = useSelector((state) => state.user.userProfile.userType);
  const [tabIndex, setTabIndex] = useState(0);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [feedback, setFeedback] = useState(essayMarks?.teacherComment || "");
  const [editGrade, setEditGrade] = useState(null);
  const [infoGrade, setInfoGrade] = useState(false);
  const updateMarkMutation = useUpdateEssayMark();
  const updateComment = useUpdateComment();
  const { data: rubrics } = useRubricsByTaskType(
    essayMarks.taskType
  );
  const { data: rubric } = useGetRubricProgressCommentsByTaskType(
    AmplifyTaskType[essayMarks.taskType]
  );

  const handleOptionChange = (e) => {
    if (e.fullName === "selectedIndex") {
      setTabIndex(e.value);
    }
  };

  const renderPopupContent = () => {
    return (
      <div>
        <CTextarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <div className="d-flex justify-content-between align-items-center w-50">
          <ActionButton
            className="mt-2"
            onClick={() => {
              updateComment.mutate({
                essayMarkId: essayMarks.essayMarkId,
                teacherComment: feedback,
              });
              setIsFeedbackVisible(false);
            }}
          >
            Add feedback
          </ActionButton>
          <Link
            onClick={() => {
              setIsFeedbackVisible(false);
            }}
          >
            Cancel
          </Link>
        </div>
      </div>
    );
  };

  const overallMarkSection = (
    <TitleRow
      title={`Overall Mark: ${essayMarks.totalScore}/${rubrics?.reduce(
        (total, rubric) => total + rubric.maxScore,
        0
      )}`}
    />
  );

  const gradeSectionData = useMemo(
    () =>
      Object.values(essayMarks.mark).sort((mark1, mark2) =>
        mark1.rubricCategory.localeCompare(mark2.rubricCategory)
      ),
    [essayMarks]
  );

  return (
    <div>
      <TitleRow title="Grading" className="mt-2">
        <Tabs
          width={300}
          className="mb-2 mt-2"
          items={tabs}
          selectedIndex={tabIndex}
          onOptionChanged={handleOptionChange}
        />
      </TitleRow>
      <>
        {tabIndex === 0 && (
          <>
            <CCard className="p-3 align-items-start mb-0">
              <div className=" mb-2 h4">
                {userType === "Student"
                  ? "Teacher's feedback"
                  : isEssayCompleted
                  ? "Teacher's feedback"
                  : "Add written feedback"}
              </div>
              {/* <CRow>{feedback}</CRow> */}
              <CRow>{essayMarks?.teacherComment}</CRow>
              {userType !== "Student"
                ? !isEssayCompleted &&
                  (essayMarks?.teacherComment ? (
                    <>
                      <div>
                        <ActionButton
                          className="mt-2"
                          onClick={() => {
                            setIsFeedbackVisible(true);
                          }}
                        >
                          Edit feedback
                        </ActionButton>
                        <ActionButton
                          className="mt-2"
                          onClick={() => {
                            updateComment.mutate({
                              essayMarkId: essayMarks.essayMarkId,
                              teacherComment: "",
                            });
                            setFeedback("");
                          }}
                        >
                          Delete feedback
                        </ActionButton>
                      </div>
                    </>
                  ) : (
                    <ActionButton
                    className="mt-2"
                      onClick={() => {
                        setIsFeedbackVisible(true);
                      }}
                    >
                      Add feedback
                    </ActionButton>
                  ))
                : null}
            </CCard>
            {overallMarkSection}
            {gradeSectionData.map((mark, index) => (
              <GradeLineItem
                key={index}
                isEssayCompleted={isEssayCompleted}
                rubricCategory={mark.rubricCategory}
                progression={getProgressComment(
                  rubrics,
                  mark.rubricCategory,
                  mark.score + 1
                )}
                chatGptResponse={chatGptResponse}
                maxScore={
                  rubrics?.find(
                    (rubric) =>
                      rubric.rubricName.toLowerCase() ===
                      mark.rubricCategory.toLowerCase()
                  )?.maxScore
                }
                score={mark.score}
                maximumScore={10}
                comment={getProgressComment(
                  rubrics,
                  mark.rubricCategory,
                  mark.score
                )}
                setEditGrade={() => setEditGrade(mark)}
              />
            ))}
            <>
              <CModal
                show={isFeedbackVisible}
                onClose={() => {
                  setIsFeedbackVisible(false);
                }}
                centered
              >
                <CModalHeader>Add feedback</CModalHeader>
                <CModalBody>{renderPopupContent()}</CModalBody>
              </CModal>
            </>
            <EditGrade
              show={Boolean(editGrade)}
              onClose={() => {
                setEditGrade(null);
              }}
              rubrics={rubrics}
              submitEdit={(values) => {
                updateMarkMutation.mutate({
                  ...values,
                  scorerId: userId,
                  essayMarkId: essayMarks.essayMarkId,
                });
              }}
              rubricCategory=""
              {...editGrade}
              prompt={prompt}
            />
            <>
              <CModal
                show={infoGrade}
                onClose={() => {
                  setInfoGrade(false);
                }}
                centered
              >
                <CModalHeader>Grade provided by Elastik: TBD</CModalHeader>
                <CModalBody>
                  <p>
                    lorem ipsum dolor Lorem ipsum dolor sit amet consectetur,
                    adipisicing elit. Consectetur, fuga.
                  </p>
                  <div className="d-flex justify-content-between align-items-center w-50">
                    <ActionButton
                      className="p-2"
                      onClick={() => {
                        setInfoGrade(false);
                      }}
                    >
                      Ok
                    </ActionButton>
                  </div>
                </CModalBody>
              </CModal>
            </>
          </>
        )}
        {tabIndex === 1 && (
          <>
            {overallMarkSection}
            <RubricGridTab
              rubric={rubric}
              taskType={AmplifyTaskType[essayMarks.taskType]}
              essayMarks={essayMarks}
            />
          </>
        )}
      </>
    </div>
  );
};
