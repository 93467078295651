export const getSchoolClassroomsByYearLevel = /* GraphQL */ `
  query GetSchoolClassroomsByYearLevel(
    $schoolID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolClassroomsByYearLevel(
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        classroomID
        classroom {
          className
          classType
          id
        }
      }
    }
  }
`;
