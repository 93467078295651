import { API, graphqlOperation } from "aws-amplify";
import { useQuery } from "react-query";
import { AmplifyTaskType } from "./components/teacher/EssayMarks";

export const getActivitiesByClassroomId = /* GraphQL */ `
  query getActivitiesByClassroomId($classroomID: ID) {
    getActivitiesByClassroomId(classroomID: $classroomID) {
      items {
        id
        promptID
        prompt {
          promptName
          taskType
          text
        }
        user {
          firstName
          lastName
        }
        AssignedStudents
        createdAt
      }
    }
  }
`;

export const getStudentHandwritingLog = /* GraphQL */ `
  query GetStudentHandwritingLogByStudentID(
    $studentID: ID
    $nextToken: String
  ) {
    getStudentHandwritingLogByStudentID(
      studentID: $studentID
      nextToken: $nextToken
    ) {
      items {
        dobFromTextract
        essayFromTextract
        id
        splitFileS3URL
        studentID
        studentNameFromTextract
        uploadID
        handwritingUpload {
          activityID
        }
      }
      nextToken
    }
  }
`;

export const getClassroomsByTeacherEmail = /* GraphQL */ `
  query getClassroomsByTeacherEmail($email: String) {
    getClassroomsByTeacherEmail(email: $email) {
      items {
        id
        classroom {
          schoolYear
          className
          id
          schoolID
          yearLevels {
            items {
              yearLevel {
                description
                numericYearCode
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getRubricProgressCommentsByTaskType = /* GraphQL */ `
  query getRubricProgressCommentsByTaskType($taskType: TaskType) {
    getRubricProgressCommentsByTaskType(taskType: $taskType) {
      items {
        progressComment
        score
        rubric {
          rubricName
        }
      }
    }
  }
`;

export const listPromptsWithoutFilter = /* GraphQL */ `
  query listPrompts($nextToken: String) {
    # TODO: We should store a special value that can be indexed for creatorEmail for global prompts
    listPrompts(nextToken: $nextToken) {
      items {
        id
        taskType
        promptName
        creatorEmail
        sharedByUserEmail
        sharedWithUserEmails
        isArchived
      }
      nextToken
    }
  }
`;

export const listPrompts = /* GraphQL */ `
  query listPrompts($nextToken: String) {
    # TODO: We should store a special value that can be indexed for creatorEmail for global prompts
    listPrompts(
      filter: {
        creatorEmail: { attributeExists: false }
        isArchived: { ne: true }
      }
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptName
        creatorEmail
        sharedByUserEmail
        sharedWithUserEmails
      }
      nextToken
    }
  }
`;

export const listAllGlobalPrompts = /* GraphQL */ `
  query listPrompts($nextToken: String) {
    listPrompts(
      filter: {
        creatorEmail: { attributeExists: false }
      }
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptName
        creatorEmail
        sharedByUserEmail
        sharedWithUserEmails
        isArchived
      }
      nextToken
    }
  }
`;

export const getPromptsByCreator = /* GraphQL */ `
  query getPromptsByCreator(
    $creatorEmail: String,
    $filter: ModelPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPromptsByCreator(
      creatorEmail: $creatorEmail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        promptName
        taskType
        text
        stimulus
        creatorEmail
        image {
          bucket
          key
          region
        }
        sharedByUserEmail
        sharedWithUserEmails
        isArchived
      }
      nextToken
    }
  }
`;

export const getStudentsByClassroom = /* GraphQL */ `
  query getStudentsByClassroom($classroomID: ID, $limit: Int) {
    getStudentsByClassroom(classroomID: $classroomID, limit: $limit) {
      items {
        classroom {
          schoolID
          schoolYear
        }
        student {
          id
          firstName
          middleName
          lastName
          currentYear {
            id
            numericYearCode
            description
          }
        }
      }
    }
  }
`;

export const getRubricByTaskType = /* GraphQL */ `
  query getRubricByTaskType($taskType: TaskType) {
    getRubricByTaskType(taskType: $taskType) {
      items {
        description
        id
        rubricName
        maxScore
        progressComments {
          items {
            score
            progressComment
          }
        }
      }
    }
  }
`;

export const getPrompt = /* GraphQL */ `
  query getPrompt($id: ID!) {
    getPrompt(id: $id) {
      image {
        bucket
        key
        region
      }
      promptName
      stimulus
      text
      taskType
      isRichText
    }
  }
`;

export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      firstName
      middleName
      lastName
    }
  }
`;

export const getActivityPrompt = /* GraphQL */ `
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      prompt {
        id
        image {
          bucket
          key
          region
        }
        promptName
        stimulus
        text
        isRichText
      }
    }
  }
`;

export const getActivityByID = /* GraphQL */ `
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      id
      prompt {
        id
        image {
          bucket
          key
          region
        }
        promptName
        stimulus
        text
        isRichText
        taskType
      }
      user {
        firstName
        lastName
      }
      AssignedStudents
      createdAt
      promptID
    }
  }
`;

export const listActivitys = /* GraphQL */ `
  query listActivitys($filter: ModelActivityFilterInput!, $nextToken: String) {
    listActivitys(filter: $filter, nextToken: $nextToken) {
      items {
        id
        classroomID
        prompt {
          promptName
          taskType
        }
        creatorEmail
        user {
          userId
          firstName
          lastName
          email
        }
        classroom {
          className
          yearLevels {
            items {
              yearLevel {
                id
                description
              }
              id
            }
          }
        }
        createdAt
      }
      nextToken
    }
  }
`;

const queryKeys = {
  listPrompts: "listPrompts",
  listActivities: "listActivities",
  getStudentsByClassroom: "getStudentsByClassroom",
  activitiesByClassroom: "activitiesByClassroom",
  rubricsByTaskType: "rubricsByTaskType",
  rubricProgressCommentsByTaskType: "getRubricProgressCommentsByTaskType",
};

const graphQLCall = (...args) => API.graphql(graphqlOperation(...args));

export const useListPrompts = () =>
  useQuery(queryKeys.listPrompts, () => graphQLCall(listPrompts));

export const useActivitiesByStudentId = (studentId) =>
  useQuery([queryKeys.listActivities, studentId], async () => {
    let data = [];
    let nextToken = null;
    do {
      const response = await graphQLCall(listActivitys, {
        filter: {
          AssignedStudents: { contains: studentId },
        },
        nextToken,
      });
      data = [...data, ...response.data.listActivitys.items];
      nextToken = response.data.listActivitys.nextToken;
    } while (nextToken !== null);
    return data;
  });

export const useActvitiesByClassroom = (classroomID) =>
  useQuery([queryKeys.activitiesByClassroom, classroomID], async () => {
    const response = await graphQLCall(getActivitiesByClassroomId, {
      classroomID,
    });
    return response.data.getActivitiesByClassroomId.items;
  });

export const useRubricsByTaskType = (taskType) =>
  useQuery([queryKeys.rubricsByTaskType, taskType], async () => {
    const response = await graphQLCall(getRubricByTaskType, {
      taskType: AmplifyTaskType[taskType],
    });
    return response.data.getRubricByTaskType.items;
  });
export const useGetRubricProgressCommentsByTaskType = (taskType) =>
  useQuery([queryKeys.rubricProgressCommentsByTaskType, taskType], async () => {
    const response = await graphQLCall(getRubricProgressCommentsByTaskType, {
      taskType,
    });
    return response.data.getRubricProgressCommentsByTaskType.items;
  });

export const useGetActivityPrompt = (activityId) =>
  useQuery(
    [queryKeys.rubricProgressCommentsByTaskType, activityId],
    async () => {
      const response = await graphQLCall(getActivityPrompt, {
        id: activityId,
      });
      return response.data.getActivity.prompt;
    }
  );

export const useStudentsByClassroom = (classroomID) =>
  useQuery([queryKeys.getStudentsByClassroom, classroomID], async () => {
    const response = await graphQLCall(getStudentsByClassroom, {
      limit: 10000,
      classroomID,
    });
    return response.data.getStudentsByClassroom.items;
  });
