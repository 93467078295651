import React from "react";
import { CCard } from "@coreui/react";

const TitleRow = (props) => (
  <>
    <CCard
      className={`w-100 p-3 d-flex flex-row justify-content-between align-items-center mb-0 ${
        props.className ?? ""
      }`}
    >
      <span className="h4">{props.title}</span>
      {props.children && (
        <div className="actionButtonContainer">{props.children}</div>
      )}
    </CCard>

    {props.hint && <div className="h3 title-hint">{props.hint}</div>}
  </>
);

export default TitleRow;
